import React, { useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { SiteContext } from '../context';

const NavigationBar = ({ section, page, goback }) => {
    let navigate = useNavigate();

    const { theme } = useContext(SiteContext);


    const doGoBack = () => {
        navigate(-1);
    }

    const removeDash = (d) => {
        let text = d.toString().replace(/\-+/g, ' ');
        return text;
    }

    return (
        <div style={{'backgroundColor':theme.backgroundColor, 'color':theme.color}} className='mt-3 mx-2 p-2'>
            <Link className='text-decoration-none' to='/'>Home </Link> / {goback ? <button style={{'backgroundColor':theme.backgroundColor, 'color':theme.color}} className='border-0 text-decoration-none' onClick={() => doGoBack()}>{section}</button> : <span>{section}</span>} {page ? <span> / <span style={{ 'text-transform': 'capitalize' }}>{page.toString().replace(/\-+/g, ' ')}</span></span> : ''}
        </div>
    )
}

export default NavigationBar;