import React, {useEffect, useContext} from 'react';
import {Header, Footer} from './';
import { Outlet } from 'react-router-dom';
import { AllProducts, CategoriesMenu } from '../components';
import { SiteContext } from '../context';


const Products = () => {

    const { theme, siteMaxWidth } = useContext(SiteContext);


    useEffect(() => {
        window.scrollTo(0,0)
    },[])


    return (
        <div style={{ 'backgroundColor': theme.backgroundColor, 'color': theme.color}}>
            <Header />

            <div style={{'maxWidth': siteMaxWidth}} className='container'>
                    <AllProducts coltype={'col-md-4'} />     
            </div>
            <Footer />
        </div>
    )
}

export default Products;