import React, {useState, useEffect, useContext} from 'react';
import { NavigationBar } from '../components';
import { useParams, Link, Outlet } from 'react-router-dom';
import {Header, Footer} from './';
import { SiteContext } from '../context';

const JobCategoryList = () => {

const { apiUrl, categories } = useContext(SiteContext);

    return (
        <div>
            <Header title='Category' />
            <div className='container my-2 p-1'>

                  <Outlet />                  

            </div>
        <Footer />
        </div>
    )
}

export default JobCategoryList;