import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer } from './';
import { DashboardMenu, Pagination } from '../components';
import moment from 'moment';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { SiteContext } from '../context';
import ReactModal from 'react-modal';

const AdminPhotoAnimation = () => {

    let navigate = useNavigate();
    let location = useLocation();

    const { apiUrl } = useContext(SiteContext);

    const userData = JSON.parse(localStorage.getItem('user'));


    const [photos, setPhotos] = useState([]);
    const [total, setTotal] = useState(null);
    const [updateItem, setUpdateItem] = useState({});
    const [itemSelected, setItemSelected] = useState('');
    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        getPhotos();
    }, [])

    const getPhotos = () => {
        fetch(`${apiUrl}/api/homeanimations.php?action=fetchadmin&role=${userData.role}`, {
            method: 'get'
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 200) {
                if (res.rows.length > 0) {
                    setPhotos(res.rows);
                    setTotal(res.rows.length);
                }
            } else if (res.status === 400) {
                setMsg(res.msg)
                setTimeout(() => {
                    setMsg('')
                }, 3000)
            }
        }).catch((err) => {
            console.log(err)
        })
    }


    const handleAdd = () => {
        navigate('/addphotoanimation');
    }

    const handleUpdate = (item) => {
        navigate('/updatephotoanimation', { state: { 'photo': item } });
    }

    const handleDelete = (photoid) => {
        setItemSelected(photoid);
        setShowModal(true);
    }


    const doDelete = (itemSelected) => {
        let formData = new FormData();

        formData.append('photoid', itemSelected);
        formData.append('role', userData && userData.role);
        formData.append('action', 'delete');

        fetch(`${apiUrl}/api/homeanimations.php`, {
            method: 'post',
            body: formData
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 200) {
                setShowModal(false);
                setMsg(res.msg)
                setTimeout(() => {
                    setMsg('')
                }, 3000)
                getPhotos();
            } else if (res.status === 400) {
                setErrorMsg(res.msg)
                setTimeout(() => {
                    setErrorMsg('')
                }, 3000)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <div>
            <ReactModal
                isOpen={showModal}
                ariaHideApp={false}
                style={{
                    overlay: {
                        flex: 1,
                        justifyContent: 'center',
                        alignContent: 'center',
                        position: 'fixed',
                        top: '10%',
                        bottom: '10%',
                        left: '25%',
                        right: '25%',
                        width: '50%',
                        height: 250,
                        backgroundColor: 'rgba(255, 255, 255, 0.75)'
                    },
                    content: {
                        position: 'absolute',
                        top: '20px',
                        left: '20px',
                        right: '20px',
                        bottom: '20px',
                        border: '1px solid #ccc',
                        background: '#fff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '20px'
                    }
                }}>
                <div>
                    <p className='h3 my-1 py-1'>Confirm deletion</p>
                    <p className='my-1 py-2'>Action cannot be reversed!</p>
                    <span className='btn btn-primary' onClick={() => doDelete(itemSelected)}>Confirm</span> <span className='btn btn-secondary' onClick={() => setShowModal(false)}>Cancel</span>
                </div>
            </ReactModal>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                        <div className='container my-2'>
                            <h3>Home page Photo animation</h3>
                            <div className='my-2 d-flex justify-content-between'>
                                <div className='align-items-center'>
                                    {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                                    {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                                </div>
                                <div>
                                    <button className='btn btn-primary' onClick={() => handleAdd()}>New Photo</button>
                                </div>
                            </div>

                            {photos.length > 0 &&
                                <div className='container p-3'>
                                    <div className='row h5 p-2 bg-light'>
                                        <div className='col-1'>No</div>
                                        <div className='col-3'>Photo</div>
                                        <div className='col-4'>Caption</div>
                                        <div className='col-2'>Category</div>
                                        <div className='col-2'>Action</div>
                                    </div>
                                    {photos.map((item, i) => {
                                        return (
                                            <div key={i} className='row border-bottom py-1'>
                                                <div className='col-1'>{++i}</div>
                                                <div className='col-3'><img src={`${apiUrl}/${item.phphoto}`} height={100} width={100} alt={item.phtitle} /> <br />
                                                    <span className="small">Created by: {item.phuser}<br /> Time: {moment(item.phdate).format('Do MMM YYYY h:mma')}</span></div>
                                                <div className='col-4'>{item.phtitle}</div>
                                                <div className='col-2'>{item.section}</div>
                                                <div className='col-2'><a onClick={() => handleUpdate(item)}><i className='bi bi-pencil text-dark h4'></i></a> <a onClick={() => handleDelete(item.phid)}><i className='bi bi-trash text-danger h4'></i></a> </div>
                                            </div>)
                                    })}
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AdminPhotoAnimation;