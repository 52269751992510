import React from 'react';

const Pagination = ({limit, page, total, cangoForward, cangoBack, handleChange, doNext, doPrevious}) => {

    return (
        <div className='p-2'><span className='bg-dark p-1 rounded'>{cangoBack ? <span className='h6 bg-dark text-white border-right p-1' onClick={() => doPrevious()}> <i className='bi bi-chevron-left'></i></span> : <span className='h6 bg-dark text-white border-right'> <i className='bi bi-chevron-left'></i></span>} <span className='h6 bg-dark text-white border-right p-1'>{page} of {total > 0 && (total / limit) < 1 ? 1 : Math.ceil(total / limit)}</span> {cangoForward ? <span className='h6 bg-dark text-white border-left p-1' onClick={() => doNext()}> <i className='bi bi-chevron-right'></i></span> : <span className='h6 bg-dark text-white border-left p-1'> <i className='bi bi-chevron-right'></i></span>}</span> <span className='pr-2'><label>
        <span className='px-1'>Total: {total} | Rows per page: </span>
        <select value={limit} onChange={(event) => handleChange(event)}>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </label></span> </div>
    )
}

export default Pagination;