import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer } from './';
import { DashboardMenu, Pagination } from '../components';
import moment from 'moment';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { SiteContext } from '../context';
import { getHeading, removeTags } from '../utils';
import ReactModal from 'react-modal';

const AdminComments = () => {

    let navigate = useNavigate();
    let location = useLocation();

    const { apiUrl } = useContext(SiteContext);

    const userData = JSON.parse(localStorage.getItem('user'));


    const [comments, setComments] = useState([]);
    const [total, setTotal] = useState(null);
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const [cangoBack, setCangoBack] = useState(true);
    const [cangoForward, setCangoForward] = useState(true);
    const [updateItem, setUpdateItem] = useState({});
    const [itemSelected, setItemSelected] = useState('');
    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        getComments();
    }, [page, limit])

    const getComments = () => {
        fetch(`${apiUrl}/api/comments.php?action=fetchadmin&role=${userData.role}&page=${page}&limit=${limit}`, {
            method: 'get'
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 200) {
                if (res.rows.length > 0) {
                    setComments(res.rows);
                    setTotal(res.count);
                }
            } else if (res.status === 400) {
                setMsg(res.msg)
                setTimeout(() => {
                    setMsg('')
                }, 3000)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const doPrevious = async () => {

        if (parseInt(page) > 1) {
            await setPage(() => parseInt(page - 1));
            setCangoBack(true);
            setCangoForward(true);
        } else {
            setCangoBack(false);
            setCangoForward(true);
        }
    }

    const doNext = async () => {

        if (parseInt(page * limit) < total) {
            await setPage(() => parseInt(page + 1));
            setCangoForward(true);
            setCangoBack(true);
        } else {
            setCangoForward(false);
            setCangoBack(true);
        }
    }

    const handleChange = (event) => {
        let newLimit = event.target.value;
        setLimit(newLimit);
    }

    const handleUpdate = (item) => {
        navigate('/updatecomment', { state: { 'comment': item } });
    }

    const handleDelete = (testid) => {
        setItemSelected(testid);
        setShowModal(true);
    }


    const doDelete = (itemSelected) => {
        let formData = new FormData();

        formData.append('commid', itemSelected);
        formData.append('role', userData && userData.role);
        formData.append('action', 'delete');

        fetch(`${apiUrl}/api/comments.php`, {
            method: 'post',
            body: formData
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 200) {
                setShowModal(false);
                setMsg(res.msg)
                setTimeout(() => {
                    setMsg('')
                }, 3000)
                getComments();
            } else if (res.status === 400) {
                setErrorMsg(res.msg)
                setTimeout(() => {
                    setErrorMsg('')
                }, 3000)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const updateComments = (id, status) => {
        let commentsCopy = comments.slice();
        let comment = commentsCopy.filter((item) => item.commentid === id);

        comment[0].comm_status = status;

        setComments(commentsCopy);
    }

    const handleComment = (id, status) => {
        let formData = new FormData();

        formData.append('id', id);
        formData.append('status', status);
        formData.append('action', 'updatecomment');

        fetch(`${apiUrl}/api/comments.php`, {
            method: 'post',
            body: formData
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 200) {
                updateComments(id, status);
            } else if (res.status === 400) {
                setErrorMsg(res.msg)
                setTimeout(() => {
                    setErrorMsg('')
                }, 3000)
            }
        }).catch((err) => {
            console.log(err)
        })
    }


    return (
        <div>
            <ReactModal
                isOpen={showModal}
                ariaHideApp={false}
                style={{
                    overlay: {
                        flex: 1,
                        justifyContent: 'center',
                        alignContent: 'center',
                        position: 'fixed',
                        top: '10%',
                        bottom: '10%',
                        left: '25%',
                        right: '25%',
                        width: '50%',
                        height: 250,
                        backgroundColor: 'rgba(255, 255, 255, 0.75)'
                    },
                    content: {
                        position: 'absolute',
                        top: '20px',
                        left: '20px',
                        right: '20px',
                        bottom: '20px',
                        border: '1px solid #ccc',
                        background: '#fff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '20px'
                    }
                }}>
                <div>
                    <p className='h3 my-1 py-1'>Confirm deletion</p>
                    <p className='my-1 py-2'>Action cannot be reversed!</p>
                    <span className='btn btn-primary' onClick={() => doDelete(itemSelected)}>Confirm</span> <span className='btn btn-secondary' onClick={() => setShowModal(false)}>Cancel</span>
                </div>
            </ReactModal>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                        <div className='container my-2'>
                            <h3>Comments</h3>
                            <div className='my-2 d-flex justify-content-between'>
                                <div className='align-items-center'>
                                    {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                                    {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                                </div>
                            </div>

                            {comments.length > 0 && <Pagination limit={limit} page={page} total={total} cangoBack={cangoBack} cangoForward={cangoForward} handleChange={handleChange} doNext={doNext} doPrevious={doPrevious} />}

                            {comments.length > 0 &&
                                <div className='container p-3'>
                                    <div className='row h5 p-2 bg-light'>
                                        <div className='col-1'>No</div>
                                        <div className='col-2'>Name</div>
                                        <div className='col-4'>Message</div>
                                        <div className='col-3'>Status</div>
                                        <div className='col-2'>Action</div>
                                    </div>
                                    {comments.map((item, i) => {
                                        return (
                                            <div key={i} className='row border-bottom py-1'>
                                                <div className='col-1'>{++i}</div>
                                                <div className='col-2'><i className='bi bi-person h1'></i>
                                                    <br />{item.comm_name}<br />Show <i className='bi bi-envelope'></i>? {parseInt(item.comm_showemail) === 1 ? 'Yes' : 'No'}</div>
                                                <div className='col-4' dangerouslySetInnerHTML={{ __html: item.comment.split(' ', 15).join(' ') + '...' }} />
                                                <div className='col-3'>{parseInt(item.comm_status) === 1 ? 'Approved' : parseInt(item.comm_status) === 2 ? 'Not Approved' : 'New'}
                                                    {parseInt(item.comm_status) === 0 && <p className='py-2'><span className='btn btn-success btn-sm' onClick={() => handleComment(item.commentid, 1)}>Approve</span><span className='btn btn-danger btn-sm mx-2' onClick={() => handleComment(item.commentid, 2)}>Not Approved</span></p>}</div>
                                                <div className='col-2'><button className='btn' onClick={() => handleUpdate(item)}><i className='bi bi-pencil text-dark h4'></i></button> <button className='btn' onClick={() => handleDelete(item.commentid)}><i className='bi bi-trash text-danger h4'></i></button> </div>
                                            </div>)
                                    })}
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AdminComments;