import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SiteContext } from '../context';

const validationSchema = Yup.object().shape({
    phone: Yup.string()
        .label('Phone')
        .required('Cannot be empty')
        .min(11, 'Should be at least 11 characters')
        .max(14, 'Should not be more than 14 characters')
})

const SearchByPhone = ({usertype}) => {

    let navigate = useNavigate();

    const { apiUrl, formatAmount } = useContext(SiteContext);

    const [item, setItem] = useState({});
    const [msg, setMsg] = useState('');
    const [found, setFound] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        setFound(false);

        let formData = new FormData();
    
        formData.append('phone', values.phone);
        formData.append('role', usertype);
        formData.append('action', 'phone_search');
      
          return fetch(`${apiUrl}/api/search.php`, {
            method: 'post',
            body: formData
          })
              .then((res) => res.json())
            .then(async (res) => {
              console.log(res);
      
              if(res.status === 200) {
                  resetForm({});
                  setSubmitting(false);
                  setItem(res.results);
                  setFound(true);
                  setTimeout(() => {
                    setMsg('');
                  }, 5000);
              } else if(res.status === 400) {
                setErrorMsg(res.msg);
                setFound(false);
                setTimeout(() => {
                  setErrorMsg('');
                }, 5000);
                setSubmitting(false);
              } else {
                alert(
                      'Problem connecting to the server',
                      'Error connection to the server please check your details and try again',
                      [{ text: 'Okay' }]
                    );
              }
            })
            .catch((error) => {
              console.log('Api call error', error.message);
            });
        };
        
        
    
  
    return (
        <div className='my-1 py-1'>
              <div>
              <Formik
                    initialValues={{
                      phone: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit}>
                          <div className='form-group'>
                            <div class="input-group">
                            <Field
                              id="phone"
                              name="phone"
                              value={values.phone}
                              placeholder="Enter phone number"
                              onBlur={handleBlur('phone')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />

                          <div class="input-group-append">
                             <button type='submit' 
                              title="phone"
                              disabled={!isValid || isSubmitting}
                              onClick={handleSubmit} 
                              className="input-group-text p-2"><i className="bi bi-search p-2"></i> 
                              </button>
                              </div>
                                </div>
                                {touched.phone && errors.phone && <div className='py-1'>
                                <span className='text-danger'>{touched.phone && errors.phone}</span>
                          </div>}

                            </div>
                          </form>
                    )}
                  </Formik>
        </div>

        <div className='my-2'>{errorMsg !== '' && <span className='p-2 my-2 text-danger'>{errorMsg}</span>}</div>

        {JSON.stringify(item) !== '{}' ? <div className='my-1'>
            {found && item && <h6>Username: {item.username} Phone: {item.phone} Email: {item.email} found <Link to={item.role === 'worker' ? '/userprofile' : '/companyprofile'} state={item.id}>View</Link></h6>}
        </div> : 
        <div>
            {!found && <p>No {usertype} found with this phone number</p>}
        </div>}
        </div>
    )
}

export default SearchByPhone;