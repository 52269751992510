import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SiteContext } from '../context';

const validationSchema = Yup.object().shape({
    catname: Yup.string()
      .label('Category')
      .required()
      .min(3, 'Must have at least 3 characters'),
    catdesc: Yup.string()
      .label('Description')
      .required()
      .min(3, 'Must have at least 3 characters')
  });
  
const ProductCatForm = ({handleSubmit, setShowModal}) => {

  let navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState('');

  const { apiUrl } = useContext(SiteContext);


    return (
      <div>
        <div className='container'>

          {errorMsg !== '' ? <span className='p-2 my-2 border border-danger text-danger'>{errorMsg}</span> : null}

          <Formik
                    initialValues={{
                      id: '',
                      catname: '',
                      catdesc: '',
                      picture: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                      setTimeout(() => {
                        setShowModal(false);
                      },1000);
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit}  encype="multipart/form-data">
                        <div className='row'>
                          <div className='col-md-12'>
                          <div className='form-group my-2'>
                          <div>
                            <label htmlFor="catname">Category</label>
                          </div>
                          <Field
                            id="catname"
                            name="catname"
                            value={values.catname}
                            placeholder="Category"
                            onBlur={handleBlur('catname')}
                            autoCapitalize="none"
                            style={{
                              padding: 4
                          }}
                          className="form-control"
                          />
                          {touched.catname && errors.catname && <div className='py-1'>
                                <span className='text-danger'>{touched.catname && errors.catname}</span>
                          </div>}
                        </div>


                          <div className='form-group my-1'>
                            <div>
                              <label htmlFor="catdesc">Description</label>
                            </div>
                            <Field
                              as='textarea'
                              id="catdesc"
                              name="catdesc"
                              value={values.catdesc}
                              placeholder="Description"
                              onBlur={handleBlur('catdesc')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.catdesc && errors.catdesc && <div className='py-1'>
                                <span className='text-danger'>{touched.catdesc && errors.catdesc}</span>
                          </div>}
                        </div>

                        <div className='form-group my-2'>
                          <div>
                            <label htmlFor="picture"> Picture</label>
                          </div>
                          <input id="picture" name="picture" type="file" onChange={(event) => {
                                 setFieldValue("picture", event.currentTarget.files[0]);
                            }} />
                        </div>

                          </div>
                        </div>
                         
                          <button
                            type='submit'
                            onClick={handleSubmit}
                            title="Add"
                            disabled={!isValid || isSubmitting}
                              className='btn btn-primary'
                          >
                              Add
                          </button>

                          
                    <button
                      onClick={() => navigate(-1)}
                      title="Cancel"
                      className='btn btn-secondary mx-2'
                    >
                      Cancel
                    </button>
                        
                          </form>
                    )}
                  </Formik>
      </div>
    </div>
    )
}

export default ProductCatForm;