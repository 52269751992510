import React, { useState, useEffect, useContext } from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import { UpdateProfilePictureAdmin, UpdateProfileIdentificationAdmin, DashboardMenu, NavigationBar } from '../components';
import {Header, Footer} from './';
import { SiteContext } from '../context';

const UserProfileDisplay = () => {
  const {apiUrl} = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem('user'));

  const [user, setUser] = useState({});
  const [msg, setMsg] = useState('');
  const [updateItem, setUpdateItem] = useState({});
  const [showUpdatePictureModal, setShowUpdatePictureModal] = useState(false);

  let location = useLocation();
  
  let id = location.state;

  useEffect(() => {
    getUser();
  },[id])

  const getUser = () => {
    fetch(`${apiUrl}/api/users.php?user=${id}&action=fetchsingle&role=${userData.role}`, {
        method:'get'
      })
      .then((response) => response.json())
      .then(async(res) => {
        if(res.status === 200) {
          await setUser(res.user);
        } else if(res.status === 400) {
          setMsg(res.msg);
        }
      })
      .catch((err) => {
          console.log(err)
      })

  }

  const handlePictureUpdate = (user) => {
    setUpdateItem(user);
    setShowUpdatePictureModal(true);
  }

    return (
      <div>
          <Header />
          <div>
            <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                        <div className='container my-2'>
                        <NavigationBar section={'Users'} goback={true} />


                    <h3 className='my-2'>{user.name}'s Profile</h3>

                    <div className='container p-3'>

        
        <div className='my-1'>
          <div className='p-2'><img className='img-fluid rounded' src={`${apiUrl}/${user.photo}`} height={200} width={200} alt={user.name} /></div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Name</div>
          <div className='col-md-7 h6 p-2'>{user.name}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Username</div>
          <div className='col-md-7 h6 p-2'>{user.username}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Phone</div>
          <div className='col-md-7 h6 p-2'>{user.phone}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Email</div>
          <div className='col-md-7 h6 p-2'>{user.email}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Gender</div>
          <div className='col-md-7 h6 p-2'>{user.gender}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Age</div>
          <div className='col-md-7 h6 p-2'>{user.age}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Address</div>
          <div className='col-md-7 h6 p-2'>{user.address}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>State</div>
          <div className='col-md-7 h6 p-2'>{user.location_state}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Country</div>
          <div className='col-md-7 h6 p-2'>{user.location_country}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Citizen</div>
          <div className='col-md-7 h6 p-2'>{user.citizenship}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Can communicate in English?</div>
          <div className='col-md-7 h6 p-2'>{user.english_language}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Can communicate in French?</div>
          <div className='col-md-7 h6 p-2'>{user.french_language}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Category</div>
          <div className='col-md-7 h6 p-2'>{user.pri_interest_category}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Experience</div>
          <div className='col-md-7 h6 p-2'>{user.profession_exp_years}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Current job</div>
          <div className='col-md-7 h6 p-2'>{user.current_job_title}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Current company</div>
          <div className='col-md-7 h6 p-2'>{user.current_company}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>Qualification</div>
          <div className='col-md-7 h6 p-2'>{user.qualification}</div>
        </div>  
        <div className='row my-1'>
          <div className='col-md-5 p-2'>CV/Resume</div>
          <div className='col-md-7 h6 p-2'>{user.worker_resume && <a href={`${apiUrl}/${user.worker_resume}`}>Uploaded CV/Resume</a>}
</div>
        </div>  
    
  </div>
              </div>
              </div>
              </div>
       </div>
       <Footer />
    </div>
    )
}

export default UserProfileDisplay;