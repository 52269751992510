import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer } from './';
import { useNavigate } from 'react-router-dom';
import { DashboardMenu, NavigationBar } from '../components';
import { SiteContext } from '../context';
import ReactModal from 'react-modal';


const AdminBannerAds = () => {

  let navigate = useNavigate();

  const { apiUrl } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem('user'));

  const [banners, setBanners] = useState([]);
  const [total, setTotal] = useState(null);
  const [updateItem, setUpdateItem] = useState({});
  const [itemSelected, setItemSelected] = useState('');
  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getBanners();
  }, [])

  const getBanners = () => {
    fetch(`${apiUrl}/api/bannerads.php?action=fetch&role=${userData.role}`, {
      method: 'get'
    }).then((res) => {
      return res.json()
    }).then((res) => {

      if (res.status === 200) {
        if (res.bannerads.length > 0) {
          setBanners(res.bannerads);
        }
      } else if (res.status === 400) {
        setMsg(res.msg)
        setTimeout(() => {
          setMsg('')
        }, 3000)
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleAdd = () => {
    navigate('/banneradadd');
  }

  const handleUpdate = (banner) => {
    navigate('/banneradupdate', { state: { 'banner': banner, 'action': 'update' } });
  }

  const handleDelete = (bannerid) => {
    setItemSelected(bannerid);
    setShowModal(true);
  }


  const doDelete = (itemSelected) => {
    let formData = new FormData();

    formData.append('banner', itemSelected);
    formData.append('role', userData.role);
    formData.append('action', 'delete');

    fetch(`${apiUrl}/api/bannerads.php`, {
      method: 'post',
      body: formData
    }).then((res) => {
      return res.json()
    }).then((res) => {
      if (res.status === 200) {
        setShowModal(false);
        setMsg(res.msg)
        setTimeout(() => {
          setMsg('')
        }, 3000)
        getBanners();
      } else if (res.status === 400) {
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        }, 3000)
      }
    }).catch((err) => {
      console.log(err)
    })
  }


  return (
    <div>
      <ReactModal
        isOpen={showModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            position: 'fixed',
            top: '10%',
            bottom: '10%',
            left: '25%',
            right: '25%',
            width: '50%',
            height: 250,
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '20px',
            left: '20px',
            right: '20px',
            bottom: '20px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px'
          }
        }}>
        <div>
          <p className='h3 my-1 py-1'>Confirm deletion</p>
          <p className='my-1 py-2'>Action cannot be reversed!</p>
          <span className='btn btn-primary' onClick={() => doDelete(itemSelected)}>Confirm</span> <span className='btn btn-secondary' onClick={() => setShowModal(false)}>Cancel</span>
        </div>
      </ReactModal>
      <Header />
      <div>
        <div className='row'>
          <div className='col-md-2 bg-light'>
            <DashboardMenu />
          </div>
          <div className='col-md-10'>
            <div className='container my-2'>

              <NavigationBar section={'Banners'} goback={true} />

              <h3>Banners</h3>
              <div className='my-2 d-flex justify-content-between'>
                <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                  {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                </div>
                <div>
                  <button className='btn btn-primary' onClick={() => handleAdd()}>New Banner</button>
                </div>
              </div>


              {banners &&
                <div className='container p-3'>
                  <div className='row border p-1 bg-light'>
                    <div className='col-1'>No</div>
                    <div className='col-7'>Banner</div>
                    <div className='col-2'>Status</div>
                    <div className='col-2'>Action</div>
                  </div>
                  {banners.map((banner, i) => {
                    return (
                      <div key={i} className='row border-bottom py-1'>
                        <div className='col-1'>{++i}</div>
                        <div className='col-7'>{banner.adcode !== '' ? banner.adcode : <img className='img-fluid' src={`${apiUrl}/${banner.file_ad}`} height={100} width={200} alt={banner.title_ad} />} <br />
                          <p>From {banner.startdate_ad} to {banner.enddate_ad}</p>
                        </div>
                        <div className='col-2'>{banner.banner_status}</div>
                        <div className='col-2'><a onClick={() => handleUpdate(banner)}><i className='bi bi-pencil text-dark h4'></i></a> <a onClick={() => handleDelete(banner.id_ad)}><i className='bi bi-trash text-danger h4'></i></a> </div>
                      </div>
                    )
                  })}
                </div>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AdminBannerAds;