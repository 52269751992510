import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer } from '../screens';
import { Formik, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Cart, NavigationBar, DashboardMenu } from '.';
import { SiteContext } from '../context';

  
const OrderForm = () => {

  let navigate = useNavigate();

  const {apiUrl, userData, cart, formatAmount, getOrderTotal, manageCart, singleProduct} = useContext(SiteContext);

  const [items, setItems] = useState([]);
  const [cartContents, setCartContents] = useState([]);
  const [orderTotal, setOrderTotal] = useState('');
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    getProducts();
  },[])

  useEffect(() => {
    setCartContents(cart);
    setOrderTotal(getOrderTotal);
  },[]);

  const getProducts = () => {
    fetch(`${apiUrl}/api/products.php?action=fetch`, {
      method:'get'
      }).then((res) => {
          return res.json()
      }).then((res) => {
          if(res.status === 200) {
              setItems(res.products)      
          } else if (res.status === 400) {
              setMsg(res.msg)
              setTimeout(() => {
                setMsg('')
              },3000)
          }
      }).catch((err) => {
          console.log(err)
      })
  }
  

  const handleUser = (e) => {
    const thevalue = e.target.value;

     fetch(`${apiUrl}/api/users.php?action=fetchsingle&user=${thevalue}&role=${userData.role}`, {
        method: 'get'
    })
    .then((res) => res.json())
    .then((response) => {

        if(response.status === 200) {
            const theuser = {
                'id': response.user.id,
                'name': response.user.name,
                'email': response.user.email,
                'phone': response.user.phone,
                'address': response.user.address,
                'state': response.user.residencestate,
                'latitude': response.user.latitude,
                'longitude': response.user.longitude
            }
            setSelectedUser(theuser)
        } else if(response.status === 400) {
            setSelectedUser({})
            setErrorMsg(response.msg)
            setTimeout(() => {
                setErrorMsg('')
            },5000)
        }
    })
    .catch((err) => {
        console.log(err)
    })
  }
 
  const handleProduct = (e) => {
      const thevalue = e.target.value;

      if(thevalue !== '') {
            let picked = items.find(pkg => pkg.id === thevalue);
            if(picked !== undefined) {
                const thepackage = {
                    'orderid': '',
                    'id': picked.id,
                    'name': picked.name,
                    'sku': picked.sku,
                    'size': picked.size,
                    'price': picked.price
                }
            setSelectedProduct(thepackage);
        }
      } else if(thevalue === '') {
          let resetProduct = {id:'', name:'', price:''};
        setSelectedProduct(resetProduct);
      }
  }

  const getItemTotal = (id) => {
    const cartItems = cartContents.slice();
    const item = cartItems.filter((a) => a.id == id);

    if(item.length > 0) {
      return item[0].qty;
    } else {
      return '1';
    }
  }

  const handleSubmit = (values, { setSubmitting, resetForm }) => {

    let formData = new FormData();
  
    formData.append('productid', singleProduct ? selectedProduct.id : '');
    formData.append('productname', singleProduct ?  selectedProduct.name : '');
    formData.append('orderdetails', singleProduct ? [] : JSON.stringify(cart));
    formData.append('amount', singleProduct ? selectedProduct.price : orderTotal);
    formData.append('customerid', selectedUser.id);
    formData.append('customername', selectedUser.name);
    formData.append('customerphone', selectedUser.phone);
    formData.append('customeraddress', `${selectedUser.address} ${selectedUser.state}`);
    formData.append('customerlatitude', selectedUser.latitude);
    formData.append('customerlongitude', selectedUser.longitude);
    formData.append('paymode', values.paymethod);
    formData.append('action', 'create');
  
      return fetch(`${apiUrl}/api/orders.php`, {
        method: 'post',
        body: formData
      })
          .then((res) => res.json())
        .then(async (res) => {
  
          console.log(res);
  
          if(res.status === 200) {
              resetForm({});
              setSubmitting(false);
              setMsg(res.msg);
              setTimeout(() => {
                setMsg('');
              }, 3000);
              navigate('/transactions');
          } else if(res.status === 400) {
            setErrorMsg(res.msg);
            setTimeout(() => {
              setErrorMsg('');
            }, 5000);
            setSubmitting(false);
          } else {
            alert(
                  'Order placement failed',
                  'Error connection to the server please check your details and try again',
                  [{ text: 'Okay' }]
                );
          }
        })
        .catch((error) => {
          console.log('Api call error', error.message);
        });
    };
  

    return (
      <div>
        <Header />
        <div>
          <div className='row'>
            <div className='col-md-2 bg-light'>
                <DashboardMenu />
            </div>
            <div className='col-md-10'>
            <div className='container'>

              <NavigationBar section='Orders' page='Add order' goback={true} />

              {errorMsg !== '' ? <span className='p-2 my-2 border border-danger text-danger'>{errorMsg}</span> : null}

              <Formik
                        initialValues={{
                          paymethod: 'Card'
                        }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                          handleSubmit(values, { setSubmitting, resetForm });
                        }}
                      >
                        {({
                          handleChange,
                          values,
                          handleSubmit,
                          errors,
                          isValid,
                          isSubmitting,
                          handleBlur,
                          touched,
                          setFieldValue
                        }) => (
                          <form onSubmit={handleSubmit} encype="multipart/form-data">
                            <div className='row'>
                              <div className='col-md-6'>
                                <div className='my-2'>
                                <div>
                                  <h5>Product</h5>
                                </div>
                              
                                <Field 
                                    as='select' 
                                    name='chooseproduct' 
                                    id='chooseproduct' 
                                    value={values.chooseproduct}
                                    onChange={(e) => {
                                        handleProduct(e);
                                    }}
                                    onBlur={handleBlur('chooseproduct')}
                                    style={{
                                        padding: 4
                                    }}>
                                            <option value=''>Select product...</option>
                                    {items.map((item) => {
                                        return (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )
                                    })}
                                </Field>

                                </div>


                            {selectedProduct.name && <div className='my-1'>
                              Name: {selectedProduct.name}<br />
                              SKU: {selectedProduct.sku}<br />
                              Size: {selectedProduct.size}<br />
                              Price: {formatAmount(selectedProduct.price)}<br />
                              {!singleProduct && <p className='h5'>Qty: <button className='btn btn-white border border-dark' onClick={() => manageCart('-', selectedProduct.id, selectedProduct.name, selectedProduct.price)}><span className='h6'>-</span></button> <button  className='btn btn-white border border-dark'><span className='h6'>{getItemTotal(selectedProduct.id)}</span></button> <button  className='btn btn-white border border-dark' onClick={() => manageCart('+', selectedProduct.id, selectedProduct.name, selectedProduct.price)}><span className='h6'>+</span></button></p>}    
                            </div>}
                            {!singleProduct && <Cart />}    


                              </div>
                              <div className='col-md-6'>
                              <div className='form-group my-1'>
                              <div>
                                <h5>Enter customer ID</h5>
                              </div>
                                <Field
                                  id="chooseuser"
                                  name="chooseuser"
                                  value={values.chooseuser}
                                  onChange={(e) => {
                                      handleUser(e);
                                  }}
                                  placeholder="Enter user ID"
                                  onBlur={handleBlur('chooseuser')}
                                  autoCapitalize="none"
                                  style={{
                                    padding: 4
                                }}
                                />
                            </div>

                              {selectedUser.name && <div className='my-1'>
                              <div><h5>Customer</h5>
                              </div>
                              Name: <span className='font-weight-bolder'>{selectedUser.name}</span><br />
                              Phone: {selectedUser.phone}<br />
                              Email: {selectedUser.email}<br />
                              Address: {selectedUser.address}<br />
                              State: {selectedUser.state}<br />
                              Latitude: {selectedUser.latitude}<br />
                              Longitude: {selectedUser.longitude}<br />
                              <div className='form-group my-1'>
                              <label>
                              <Field type="radio" name="paymethod" value="Cash" /> Cash
                              </label>
                              <label className='px-3'>
                              <Field type="radio" name="paymethod" value="Card" /> Card
                              </label>
                            </div>
                            </div>}


                              </div>
                            </div>
                            {singleProduct ? selectedProduct.id && selectedUser.id && <button
                                type='submit'
                                onClick={handleSubmit}
                                title="Place Order"
                                disabled={!isValid || isSubmitting}
                                  className='btn btn-primary'
                              >
                                  Submit
                              </button> : cart.length > 0 && selectedUser.id && <button
                                type='submit'
                                onClick={handleSubmit}
                                title="Place Order"
                                disabled={!isValid || isSubmitting}
                                  className='btn btn-primary'
                              >
                                  Submit
                              </button>}
                            
                              </form>
                        )}
                      </Formik>
          </div>
        </div>
      </div>
      </div>  
      <Footer />
    </div>
    )
}

export default OrderForm;