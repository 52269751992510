import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    orderstatus: Yup.string()
      .label('Status')
      .required(),
  });
  
const UpdateOrderForm = ({handleSubmit, updateItem, setShowModal}) => {
  const [errorMsg, setErrorMsg] = useState('');


    return (
      <div>
        <div className='container'>

          {errorMsg !== '' ? <span className='p-2 my-2 border border-danger text-danger'>{errorMsg}</span> : null}

          <Formik
                    initialValues={{
                      id: updateItem.id ? updateItem.id : '',
                      orderstatus: updateItem.status ? updateItem.status : '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm, setShowModal });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                        <div className='row'>
                          <div className='col-md-6'>


                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="orderstatus">Order status</label>
                          </div>
                            <Field
                              as='select'
                              id="orderstatus"
                              name="orderstatus"
                              value={values.orderstatus}
                              placeholder="State of orderstatus"
                              onBlur={handleBlur('orderstatus')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            >
                              <option value="">Select...</option>
                              <option value="new">New</option>
                              <option value="processed">Processed</option>
                              <option value="cancelled">Cancelled</option>
                              <option value="completed">Completed</option>
                            </Field>

                          {touched.orderstatus && errors.orderstatus && <div className='py-1'>
                                <span className='text-danger'>{touched.orderstatus && errors.orderstatus}</span>
                          </div>}
                        </div>

                          </div>
                        </div>
                        <button
                            type='submit'
                            buttonType="outline"
                            onClick={handleSubmit}
                            title="Update"
                            disabled={!isValid || isSubmitting}
                            loading={isSubmitting}
                              className='btn btn-primary'
                          >
                              Update Order status
                          </button>
                        
                          </form>
                    )}
                  </Formik>
      </div>
    </div>
    )
}

export default UpdateOrderForm;