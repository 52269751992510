import React, { useState, useEffect, useContext } from 'react';
import { Formik, Field } from 'formik';
import { Header, Footer } from './';
import { useNavigate, useLocation } from 'react-router-dom';
import { DashboardMenu, NavigationBar } from '../components';
import moment from 'moment';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { SiteContext } from '../context';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .label('Title')
    .required(),
  description: Yup.string()
    .label('Description')
    .required()
    .min(5, 'Must have at least 5 characters'),
  banner_rank: Yup.string()
    .label('Banner rank')
    .required(),
  banner_position: Yup.string()
    .label('Position')
    .required()
});

const AdminBannerAdsUpdate = () => {

  let location = useLocation();
  let navigate = useNavigate();

  const [startDate, setStartDate] = useState(new Date(location.state.banner.startdate_ad));
  const [endDate, setEndDate] = useState(new Date(location.state.banner.enddate_ad));
  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [uploadedPic, setUploadedPic] = useState(null);

  const { apiUrl, categories, userData } = useContext(SiteContext);


  const handleSubmit = (values, { setSubmitting, resetForm }) => {

    let formData = new FormData();

    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('adsense_code', values.adsense_code);
    formData.append('banner_rank', values.banner_rank);
    formData.append('picture', values.picture);
    formData.append('ad_url', values.ad_url);
    formData.append('start_date', moment(startDate).format('YYYY-MM-DD'));
    formData.append('end_date', moment(endDate).format('YYYY-MM-DD'));
    formData.append('banner_status', values.status ? 'active' : 'inactive');
    formData.append('position', values.banner_position);
    formData.append('banner', values.bannerid);
    formData.append('role', userData && userData.role);
    formData.append('action', 'update');

    return fetch(`${apiUrl}/api/bannerads.php`, {
      method: 'post',
      body: formData
    })
      .then((res) => res.json())
      .then(async (res) => {

        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg('');
          }, 3000);
          setTimeout(() => {
            navigate('/bannerads');
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg('');
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            'Banner update failed',
            'Error connection to the server please check your details and try again',
            [{ text: 'Okay' }]
          );
        }
      })
      .catch((error) => {
        console.log('Api call error', error.message);
      });
  };


  return (
    <div>
      <Header />

      <div>
        <div className='row'>
          <div className='col-md-2 bg-light'>
            <DashboardMenu />
          </div>
          <div className='col-md-10'>
            <div className='container my-2'>

              <NavigationBar section={'Banners'} goback={true} />

              <h3>Update Banner ad</h3>
              <div className='my-2 d-flex justify-content-between'>
                <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                  {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                </div>
              </div>

              <Formik
                initialValues={{
                  bannerid: location.state.banner.id_ad != '' ? location.state.banner.id_ad : '',
                  title: location.state.banner.title_ad != '' ? location.state.banner.title_ad : '',
                  description: location.state.banner.descr_ad != '' ? location.state.banner.descr_ad : '',
                  adsense_code: location.state.banner.adcode != '' ? location.state.banner.adcode : '',
                  banner_rank: location.state.banner.banner_rank != '' ? location.state.banner.banner_rank : '',
                  banner_position: location.state.banner.position_ad != '' ? location.state.banner.position_ad : '',
                  status: location.state.banner.banner_status == 'active' ? true : false,
                  picture: location.state.banner.file_ad != '' ? location.state.banner.file_ad : '',
                  ad_url: location.state.banner.url_ad != '' ? location.state.banner.url_ad : ''
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSubmit(values, { setSubmitting, resetForm });
                }}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  isValid,
                  isSubmitting,
                  handleBlur,
                  touched,
                  setFieldValue
                }) => (
                  <form onSubmit={handleSubmit} encype="multipart/form-data">
                    <div className='row'>
                      <div className='col-md-6'>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="title">Title</label>
                          </div>
                          <Field
                            id="title"
                            name="title"
                            value={values.title}
                            onBlur={handleBlur('title')}
                            autoCapitalize="none"
                            style={{
                              padding: 4
                            }}
                            className="form-control"
                          />

                          {touched.title && errors.title && <div className='py-1'>
                            <span className='text-danger'>{touched.title && errors.title}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="description">Description</label>
                          </div>
                          <Field
                            as='textarea'
                            id="description"
                            name="description"
                            value={values.description}
                            onBlur={handleBlur('description')}
                            autoCapitalize="none"
                            style={{
                              padding: 4
                            }}
                            className="form-control"
                            row='5'
                          />
                          {touched.description && errors.description && <div className='py-1'>
                            <span className='text-danger'>{touched.description && errors.description}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="adsense_code">Adsense code</label>
                          </div>
                          <Field
                            as='textarea'
                            id="adsense_code"
                            name="adsense_code"
                            value={values.adsense_code}
                            onBlur={handleBlur('adsense_code')}
                            autoCapitalize="none"
                            style={{
                              padding: 4
                            }}
                            className="form-control"
                            row='5'
                          />
                          {touched.adsense_code && errors.adsense_code && <div className='py-1'>
                            <span className='text-danger'>{touched.adsense_code && errors.adsense_code}</span>
                          </div>}
                        </div>

                        <div className='form-group my-2'>
                          <div>
                            <label htmlFor="banner_rank">Banner rank</label>
                          </div>
                          <Field
                            as="select"
                            id="banner_rank"
                            name="banner_rank"
                            value={values.banner_rank}
                            onBlur={handleBlur('banner_rank')}
                            autoCapitalize="none"
                            style={{
                              padding: 4
                            }}
                            className="form-control"
                          >
                            <option value="">Select...</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>

                          </Field>

                          {touched.banner_rank && errors.banner_rank && <div className='py-1'>
                            <span className='text-danger'>{touched.banner_rank && errors.banner_rank}</span>
                          </div>}
                        </div>



                        <div className='form-group my-2'>
                          <div>
                            <label htmlFor="picture"> Image</label>
                          </div>
                          <input id="picture" name="picture" type="file" onChange={(event) => {
                            setFieldValue("picture", event.currentTarget.files[0]);
                          }} />
                          {location.state.banner.file_ad != "" && <div><img className='my-2 img-fluid' src={`${apiUrl}/${location.state.banner.file_ad}`} height={100} width={150} alt="picture" /></div>}

                        </div>

                        {uploadedPic && <img className='my-2' src={URL.createObjectURL(uploadedPic)} class="img-fluid mt-2" width={80} height={80} id="output_image" />}

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="ad_url">Website Url</label>
                          </div>
                          <Field
                            id="ad_url"
                            name="ad_url"
                            value={values.ad_url}
                            onBlur={handleBlur('ad_url')}
                            autoCapitalize="none"
                            style={{
                              padding: 4
                            }}
                            className="form-control"
                          />
                          {touched.ad_url && errors.ad_url && <div className='py-1'>
                            <span className='text-danger'>{touched.ad_url && errors.ad_url}</span>
                          </div>}
                        </div>

                        <div className='form-group my-2'>
                          <div>
                            <label htmlFor="banner_position">Banner position</label>
                          </div>
                          <Field
                            as="select"
                            id="banner_position"
                            name="banner_position"
                            value={values.banner_position}
                            onBlur={handleBlur('banner_position')}
                            autoCapitalize="none"
                            style={{
                              padding: 4
                            }}
                            className="form-control"
                          >
                            <option value="">Select...</option>
                            <option value="side">Side</option>
                            <option value="top">Top</option>

                          </Field>

                          {touched.banner_position && errors.banner_position && <div className='py-1'>
                            <span className='text-danger'>{touched.banner_position && errors.banner_position}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="start_date">From</label>
                          </div>
                          <DatePicker dateFormat={"yyyy-MM-dd"} selected={startDate} onChange={(date) => {
                            setStartDate(date);
                          }} />
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="end_date">To</label>
                          </div>
                          <DatePicker dateFormat={"yyyy-MM-dd"} selected={endDate} onChange={(date) => setEndDate(date)} minDate={startDate} />

                        </div>

                        <div className='form-group my-1 py-2'>
                          <div>
                            <label htmlFor="status">Active?</label> <Field type="checkbox" name="status" /> {values.status ? 'Active' : 'Inactive'}
                          </div>
                        </div>

                      </div>
                    </div>


                    <button
                      type='submit'
                      onClick={handleSubmit}
                      title="Update"
                      disabled={!isValid || isSubmitting}
                      className='btn btn-primary my-2'
                    >
                      Update
                    </button>

                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AdminBannerAdsUpdate;