import React, {useState, useEffect, useContext} from 'react';
import {Header, Footer} from './';
import { TableUser, Pagination, NavigationBar } from '../components';
import { DashboardMenu, SearchByPhone } from '../components';
import { SiteContext } from '../context';

const Workers = () => {

const { apiUrl } = useContext(SiteContext);

const [duplicates, setDuplicates] = useState([]);
const [users, setUsers] = useState([]);
const [total, setTotal] = useState(null);
const [limit, setLimit] = useState(100);
const [page, setPage] = useState(1);
const [cangoBack, setCangoBack] = useState(true);
const [cangoForward, setCangoForward] = useState(true);
const [updateItem, setUpdateItem] = useState({});
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');
const [showModal, setShowModal] = useState(false);
const [showUpdateModal, setShowUpdateModal] = useState(false);

useEffect(() => {
  getUsers();
},[page,limit])

const getUsers = () => {
  fetch(`${apiUrl}/api/users.php?action=fetch&role=worker&page=${page}&limit=${limit}`, {
    method:'get'
  }).then((res) => {
      return res.json();
  }).then((res) => {
      if(res.status === 200) {
          setUsers(res.rows) 
          setTotal(res.count);       
      } else if (res.status === 400) {
          setMsg('No workers found')
          setTimeout(() => {
            setMsg('')
          },3000)
      }
  }).catch((err) => {
      console.log(err)
  })
}

const doPrevious = async () => {
 
  if(parseInt(page) > 1) {
    await setPage(() => parseInt(page - 1));
    setCangoBack(true);
    setCangoForward(true);
  } else {
    setCangoBack(false);
    setCangoForward(true);
  }
}

const doNext = async () => {

  if(parseInt(page * limit) < total) { 
    await setPage(() => parseInt(page + 1));
    setCangoForward(true);
    setCangoBack(true);
  } else {
    setCangoForward(false);
    setCangoBack(true);
  }
}

const handleChange = (event) => {
  let newLimit = event.target.value;
  setLimit(newLimit);
}

const doDelete = id => {
    fetch(`${apiUrl}/api/users.php?id=${id}&action=delete&role=worker`, {
      method:'get'
    }).then((res) => {
        return res.json()
    }).then((res) => {
      if(res.status === 200) {
        getUsers();
        setMsg(res.msg)
        setTimeout(() => {
          setMsg('')
        },3000)  
      } else if(res.status === 400) {
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        },3000)  
      }
    }).catch((err) => {
        console.log(err)
    })
  }


    return (
        <div>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                    <div className='container my-2'>
                      <NavigationBar section={'Workers'} goback={false} />
                <h3>Workers</h3>
                <div className='my-2 d-flex justify-content-between'>
                  <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                  </div>
                </div>

                <div className='d-flex justify-content-between align-contents-center'>
                  <div className='col-8'>
                      <SearchByPhone usertype='worker' />
                    </div>
                  </div>

                <Pagination limit={limit} page={page} total={total} cangoBack={cangoBack} cangoForward={cangoForward} handleChange={handleChange} doNext={doNext} doPrevious={doPrevious} />

                {duplicates.length > 0 && <p className='text-danger my-2 p-1 border border-danger'>{duplicates.map((item,i) => <div key={i}>{item}</div>)}</p>}
                <TableUser users={users} onDelete={doDelete} />
            </div>
                    </div>
                </div>
            </div>
          <Footer />
        </div>
    )
}

export default Workers;