import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {Header, Footer} from './';
import { SiteContext } from '../context';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    name: Yup.string()
      .label('Name')
      .required()
      .min(4, 'Must have at least 4 characters'),
    age: Yup.string()
      .label('Age')
      .required(),
    gender: Yup.string()
        .label('Gender')
        .required(),
    phone: Yup.string()
      .label('Phone')
      .required('')
      .min(11, 'Phone number should be at least 11 characters')
      .max(14, 'Should not be more than 14 characters'),
    country: Yup.string()
      .label('Country')
      .required('Please select a country'),
    address: Yup.string()
        .label('Address')
        .required('Please provide address'),
    locstate: Yup.string()
      .label('State')
      .required()
      .min(3, 'State must have at least 3 characters '),
    citizen: Yup.string()
      .label('Citizen')
      .required('Select a country'),
    experience: Yup.string()
      .label('Years of experience')
      .required(),
    langenglish: Yup.string()
      .label('English language')
      .required(),
    langfrench: Yup.string()
      .label('French language')
      .required(),
    currentjob: Yup.string()
      .label('Current job')
      .required(),
    currentcompany: Yup.string()
      .label('Current company')
      .required(),
    qualification: Yup.string()
      .label('Qualification')
      .required(),
    photo: Yup.string()
      .label('Photo')
      .required('Upload your photo'),
    resume: Yup.string()
      .label('CV/Resume')
      .required('Upload your CV/Resume')
  });

  const arrayrange = (start, end) => {
    let myArray = [];
    for (let i = start; i <= end; i++) {
      myArray.push(i);
    }
    return myArray;
};

const africancountries = [
    {"id": 1, "name": "Algeria"},
    {"id": 2, "name": "Angola"},
    {"id": 3, "name": "Benin"},
    {"id": 4, "name": "Botswana"},
    {"id": 5, "name": "Burkina Faso"},
    {"id": 6, "name": "Burundi"},
    {"id": 7, "name": "Cameroun"},
    {"id": 8, "name": "Canary Islands"},
    {"id": 9, "name": "Cape Verde"},
    {"id": 10, "name": "Central African Republic"},
    {"id": 11, "name": "Chad"},
    {"id": 12, "name": "Comoros"},
    {"id": 13, "name": "Côte d'Ivoire"},
    {"id": 14, "name": "Democratic Republic of the Congo"},
    {"id": 15, "name": "Djibouti"},
    {"id": 16, "name": "Egypt"},
    {"id": 17, "name": "Equatorial Guinea"},
    {"id": 18, "name": "Eritrea"},
    {"id": 19, "name": "Ethiopia"},
    {"id": 20, "name": "Gabon"},
    {"id": 21, "name": "Gambia"},
    {"id": 22, "name": "Ghana"},
    {"id": 23, "name": "Guinea"},
    {"id": 24, "name": "Guinea-Bissau"},
    {"id": 25, "name": "Kenya"},
    {"id": 26, "name": "Lesotho"},
    {"id": 27, "name": "Liberia"},
    {"id": 28, "name": "Libya"},
    {"id": 29, "name": "Madagascar"},
    {"id": 30, "name": "Madeira"},
    {"id": 31, "name": "Malawi"},
    {"id": 32, "name": "Mauritania"},
    {"id": 33, "name": "Mauritius"},
    {"id": 34, "name": "Morocco"},
    {"id": 35, "name": "Mozambique"},
    {"id": 36, "name": "Namibia"},
    {"id": 37, "name": "Niger"},
    {"id": 38, "name": "Nigeria"},
    {"id": 39, "name": "Republic of the Congo"},
    {"id": 40, "name": "Rwanda"},
    {"id": 41, "name": "Saint Helena"},
    {"id": 42, "name": "São Tomé and Príncipe"},
    {"id": 43, "name": "Senegal"},
    {"id": 44, "name": "Seychelles"},
    {"id": 45, "name": "Sierra Leone"},
    {"id": 46, "name": "Somalia"},
    {"id": 47, "name": "South Africa"},
    {"id": 48, "name": "Sudan"},
    {"id": 49, "name": "Swaziland"},
    {"id": 50, "name": "Tanzania"},
    {"id": 51, "name": "Togo"},
    {"id": 52, "name": "Tunisia"},
    {"id": 53, "name": "Uganda"},
    {"id": 54, "name": "Western Sahara"},
    {"id": 55, "name": "Zambia"},
    {"id": 56, "name": "Zimbabwe"}
  ];

const SignupStep2 = () => {
  const [showForm, setShowForm] = useState(true);
  const [applyemail, setApplyemail] = useState('');
  const [uploadedPic, setUploadedPic] =  useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [msg, setMsg] = useState('');

    const {apiUrl, handleSignIn, categories} = useContext(SiteContext);
    let navigate = useNavigate();
    let location = useLocation();
    let {email, rkey}  = location.state || '';

    useEffect(() => {
      setApplyemail(email);
    },[])

    const handleSubmit = (values, { setSubmitting, resetForm }) => {

      setSubmitting(false)
      
      let formData = new FormData();

      formData.append('name', values.name);
      formData.append('email', email != '' && email);
      formData.append('uuid', rkey != '' && rkey);
      formData.append('phone', values.phone);
      formData.append('locstate', values.locstate);
      formData.append('gender', values.gender);
      formData.append('age', values.age);
      formData.append('address', values.address);
      formData.append('country', values.country);
      formData.append('citizen', values.citizen);
      formData.append('langenglish', values.langenglish);
      formData.append('langfrench', values.langfrench);
      formData.append('experience', values.experience);
      formData.append('currentjob', values.currentjob);
      formData.append('currentcompany', values.currentcompany);
      formData.append('photo', values.photo);
      formData.append('qualification', values.qualification);
      formData.append('resume', values.resume);
      formData.append('action', 'applystep2');

        return fetch(`${apiUrl}/api/users.php`, {
          method: 'post',
          body: formData
        })
          .then((res) => res.json())
          .then(async (res) => {
    
            if(res.status === 200) {
              resetForm({});
              setSubmitting(false);
              setMsg(res.msg);
              setApplyemail('');
              setShowForm(false);
             await handleSignIn(res.user);
             navigate('/signup-complete', {state: {completemsg: 'You have successfully completed the signup and application process'}})
              } 
              else if(res.status === 400) {
              setErrorMsg(res.msg);
              setTimeout(() => {
                setErrorMsg('');
              }, 5000);
              setSubmitting(false);
            } else {
              alert(
                    'Submission failed',
                    'Error connection to the server please check your details and try again',
                    [{ text: 'Okay' }]
                  );
            }
          })
          .catch((error) => {
            console.log('Api call error', error.message);
          });
      };
    

    return (
      <div>
        <Header />
        <div className='container'>

        <h3 className='my-3'>Sign up Step 2 - {email != '' && email}</h3>

        {msg !== '' ? <div className='my-4'><span className='p-2 my-2 text-dark'>{msg}</span></div> : null}
        {errorMsg !== '' ? <div className='my-4'><span className='p-2 my-2 border border-danger text-danger'>{errorMsg}</span></div> : null}

          <div className='row my-3 mx-auto p-2'>
            <div className='col-4 text-center text-light bg-success'>
              Step 1 of 3
            </div>  
            <div className='col-4 text-center text-light bg-success'>
              Step 2 of 3
            </div>  
            <div className='col-4 text-center text-dark bg-light'>
              Step 3 of 3
            </div>  
          </div>

          {errorMsg !== '' ? <span className='p-2 my-2 border border-danger text-danger'>{errorMsg}</span> : null}

          {showForm && applyemail != '' && <Formik
                    initialValues={{
                      name:'',
                      phone:'',
                      locstate: '',
                      address:'',
                      country: '',
                      citizen: '',
                      gender:'',
                      age: '',
                      langenglish:'',
                      langfrench:'',
                      currentjob: '',
                      currentcompany: '',
                      photo: '',
                      qualification: '',
                      experience: '',
                      resume: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                      <div className='row my-3 py-3'>
                        <div className='col-md-8'>


                        <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                          <label htmlFor="photo">Upload your picture</label>
                          {uploadedPic && <img className='my-2' src={URL.createObjectURL(uploadedPic)} class="img-fluid mt-2" width={200} height={100} id="output_image"/>}
                        </div>
                        <div className='col-md-9'>
                            <input id="photo" name="photo" type="file" onChange={(event) => {
                                setFieldValue("photo", event.currentTarget.files[0]);
                                setUploadedPic(event.currentTarget.files[0]);
                            }} />
            
                             {touched.photo && errors.photo && <div className='py-1'>
                              <span className='text-danger'>{touched.photo && errors.photo}</span>
                        </div>}
                      </div>
                      </div>


                        <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="name">Full name</label>
                          </div>
                          <div className='col-md-9'>
                          <Field
                            id="name"
                            name="name"
                            value={values.name}
                            onBlur={handleBlur('name')}
                            autoCapitalize="none"
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                        {touched.name && errors.name && <div className='py-1'>
                              <span className='text-danger'>{touched.name && errors.name}</span>
                        </div>}
                      </div>
                      </div>
          
                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="phone">Phone</label>
                          </div>
                          <div className='col-md-9'>
                          <Field
                            id="phone"
                            name="phone"
                            value={values.phone}
                            onBlur={handleBlur('phone')}
                            autoCapitalize="none"
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                        {touched.phone && errors.phone && <div className='py-1'>
                              <span className='text-danger'>{touched.phone && errors.phone}</span>
                        </div>}
                      </div>
                      </div>


                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="country">Country</label>
                          </div>
                          <div className='col-md-9'>
                            <Field
                              as='select'
                              id="country"
                              name="country"
                              value={values.country}
                              onBlur={handleBlur('country')}
                              autoCapitalize="none"
                              style={{
                                padding: 10
                            }}
                            className="form-control"
                            >
                              <option value="">Select country...</option>
                              {africancountries.map((item,i) => {
                                return <option key={item.id} value={item.name}>{item.name}</option>
                              })}
                            </Field>
                            
                            {touched.country && errors.country && <div className='py-1'>
                                <span className='text-danger'>{touched.country && errors.country}</span>
                          </div>}
                        </div>
                        </div>
          
                        <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="address">Address</label>
                          </div>
                          <div className='col-md-9'>
                          <Field
                            as='textarea'
                            id="address"
                            name="address"
                            value={values.address}
                            onBlur={handleBlur('address')}
                            autoCapitalize="none"
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                        {touched.address && errors.address && <div className='py-1'>
                              <span className='text-danger'>{touched.address && errors.address}</span>
                        </div>}
                      </div>
                      </div>

                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="locstate">State</label>
                          </div>
                          <div className='col-md-9'>
                          <Field
                            id="locstate"
                            name="locstate"
                            value={values.locstate}
                            onBlur={handleBlur('locstate')}
                            autoCapitalize="none"
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                        {touched.locstate && errors.locstate && <div className='py-1'>
                              <span className='text-danger'>{touched.locstate && errors.locstate}</span>
                        </div>}
                      </div>
                      </div>


                        <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="citizen">Citizenship</label>
                          </div>
                          <div className='col-md-9'>
                            <Field
                              as='select'
                              id="citizen"
                              name="citizen"
                              value={values.citizen}
                              onBlur={handleBlur('citizen')}
                              autoCapitalize="none"
                              style={{
                                padding: 10
                            }}
                            className="form-control"
                            >
                              <option value="">Select citizen...</option>
                              {africancountries.map((item,i) => {
                                return <option key={item.id} value={item.name}>{item.name}</option>
                              })}
                            </Field>
                            
                            {touched.citizen && errors.citizen && <div className='py-1'>
                                <span className='text-danger'>{touched.citizen && errors.citizen}</span>
                          </div>}
                        </div>
                        </div>
          
                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                          <label htmlFor="gender">Gender</label>
                        </div>
                        <div className='col-md-9'>
                            <span className='mx-2'>
                                <Field type="radio" name="gender" value="Male" /> Male
                            </span>
                            <Field type="radio" name="gender" value="Female" /> Female
                      </div>
                      </div>

                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="age">Age</label>
                          </div>
                          <div className='col-md-9'>
                            <Field
                              as='select'
                              id="age"
                              name="age"
                              value={values.age}
                              onBlur={handleBlur('age')}
                              autoCapitalize="none"
                              style={{
                                padding: 10
                            }}
                            className="form-control"
                            >
                              <option value="">Select age...</option>
                              {arrayrange(18,50).map((item,i) => {
                                return <option key={i} value={item}>{item}</option>
                              })}
                            </Field>
                            
                            {touched.age && errors.age && <div className='py-1'>
                                <span className='text-danger'>{touched.age && errors.age}</span>
                          </div>}
                        </div>
                        </div>


                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                          <label htmlFor="langenglish">Can communicate in English?</label>
                        </div>
                        <div className='col-md-9'>
                            <span className='mx-2'>
                                <Field type="radio" name="langenglish" value="Yes" /> Yes
                            </span>
                            <Field type="radio" name="langenglish" value="No" /> No
                      </div>
                      </div>

                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                          <label htmlFor="langfrench">Can you communicate in French?</label>
                        </div>
                        <div className='col-md-9'>
                            <span className='mx-2'>
                                <Field type="radio" name="langfrench" value="Yes" /> Yes
                            </span>
                            <Field type="radio" name="langfrench" value="No" /> No
                      </div>
                      </div>

                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="experience">Years of experience</label>
                          </div>
                          <div className='col-md-9'>
                            <Field
                              as='select'
                              id="experience"
                              name="experience"
                              value={values.experience}
                              onBlur={handleBlur('experience')}
                              autoCapitalize="none"
                              style={{
                                padding: 10
                            }}
                            className="form-control"
                            >
                              <option value="">Select years of experience...</option>
                              {arrayrange(1,35).map((item,i) => {
                                return <option key={i} value={item}>{item}</option>
                              })}
                            </Field>
                            
                            {touched.experience && errors.experience && <div className='py-1'>
                                <span className='text-danger'>{touched.experience && errors.experience}</span>
                          </div>}
                        </div>
                        </div>

                        <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                          <label htmlFor="qualification">Highest qualification</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            id="qualification"
                            name="qualification"
                            value={values.qualification}
                            onBlur={handleBlur('qualification')}
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                        
                        {touched.qualification && errors.qualification && <div className='py-1'>
                              <span className='text-danger'>{touched.qualification && errors.qualification}</span>
                        </div>}
                      </div>
                      </div>
          
                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                          <label htmlFor="currentjob">Current job</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            id="currentjob"
                            name="currentjob"
                            value={values.currentjob}
                            onBlur={handleBlur('currentjob')}
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                        
                        {touched.currentjob && errors.currentjob && <div className='py-1'>
                              <span className='text-danger'>{touched.currentjob && errors.currentjob}</span>
                        </div>}
                      </div>
                      </div>

                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                          <label htmlFor="currentcompany">Current company</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            id="currentcompany"
                            name="currentcompany"
                            value={values.currentcompany}
                            onBlur={handleBlur('currentcompany')}
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                        
                        {touched.currentcompany && errors.currentcompany && <div className='py-1'>
                              <span className='text-danger'>{touched.currentcompany && errors.currentcompany}</span>
                        </div>}
                      </div>
                      </div>

                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                          <label htmlFor="resume">Upload your CV/Resume (pdf)</label>
                        </div>
                        <div className='col-md-9'>
                            <input id="resume" name="resume" type="file" onChange={(event) => {
                                setFieldValue("resume", event.currentTarget.files[0]);
                            }} />
            
                             {touched.resume && errors.resume && <div className='py-1'>
                              <span className='text-danger'>{touched.resume && errors.resume}</span>
                        </div>}
                      </div>
                      </div>

                       <button
                          type='submit'
                          onClick={handleSubmit}
                          title="Submit"
                          disabled={!isValid || isSubmitting}
                            className='btn btn-primary'
                        >
                            Submit
                        </button>
                        </div>
                      </div>
                     
                        </form>                    )}
                  </Formik>}
      </div>
      <Footer />
    </div>
    )
}

export default SignupStep2;