import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {Header, Footer} from './';
import { SiteContext } from '../context';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .label('Phone')
      .required('')
      .min(11, 'Phone number should be at least 11 characters')
      .max(14, 'Should not be more than 14 characters'),
    country: Yup.string()
      .label('Country')
      .required('Please select a country'),
    address: Yup.string()
        .label('Address')
        .required('Please provide address'),
    locstate: Yup.string()
      .label('State')
      .required()
      .min(3, 'State must have at least 3 characters '),
    primaryareas: Yup.array()
      .label('Areas of interest')
      .required(),
    staffstrength: Yup.string()
      .label('Staff strength')
      .required(),
    photo: Yup.string()
      .label('Photo')
      .required('Upload your company logo')
  });


const SignupCompanyStep2 = () => {
  const [showForm, setShowForm] = useState(true);
  const [applyemail, setApplyemail] = useState('');
  const [uploadedPic, setUploadedPic] =  useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [msg, setMsg] = useState('');

    const {apiUrl, handleSignIn, categories} = useContext(SiteContext);
    let navigate = useNavigate();
    let location = useLocation();
    let {email, rkey}  = location.state || '';

    useEffect(() => {
      setApplyemail(email);
    },[])

    const handleSubmit = (values, { setSubmitting, resetForm }) => {

      setSubmitting(false)
      
      let formData = new FormData();

      formData.append('email', email != '' && email);
      formData.append('uuid', rkey != '' && rkey);
      formData.append('phone', values.phone);
      formData.append('locstate', values.locstate);
      formData.append('address', values.address);
      formData.append('country', values.country);
      formData.append('primaryareas', JSON.stringify(values.primaryareas));
      formData.append('staffstrength', values.staffstrength);
      formData.append('photo', values.photo);
      formData.append('action', 'applycompanystep2');

        return fetch(`${apiUrl}/api/users.php`, {
          method: 'post',
          body: formData
        })
          .then((res) => res.json())
          .then(async (res) => {
    
            if(res.status === 200) {
              resetForm({});
              setSubmitting(false);
              setMsg(res.msg);
              setApplyemail('');
              setShowForm(false);
             await handleSignIn(res.user);
             navigate('/signup-company-complete', {state: {completemsg: 'You have successfully completed the signup and application process for a company'}})
              } 
              else if(res.status === 400) {
              setErrorMsg(res.msg);
              setTimeout(() => {
                setErrorMsg('');
              }, 5000);
              setSubmitting(false);
            } else {
              alert(
                    'Submission failed',
                    'Error connection to the server please check your details and try again',
                    [{ text: 'Okay' }]
                  );
            }
          })
          .catch((error) => {
            console.log('Api call error', error.message);
          });
      };
    

    return (
      <div>
        <Header />
        <div className='container'>
          <div className='row my-3 mx-auto p-2'>
            <div className='col-4 text-center text-light bg-success'>
              Step 1 of 3
            </div>  
            <div className='col-4 text-center text-light bg-success'>
              Step 2 of 3
            </div>  
            <div className='col-4 text-center text-dark bg-light'>
              Step 3 of 3
            </div>  
          </div>


        <h3 className='my-3'>Sign up Step 2 - {email != '' && email}</h3>

        {msg !== '' ? <div className='my-4'><span className='p-2 my-2 text-dark'>{msg}</span></div> : null}
        {errorMsg !== '' ? <div className='my-4'><span className='p-2 my-2 border border-danger text-danger'>{errorMsg}</span></div> : null}

          
          {showForm && <Formik
                    initialValues={{
                      name:'',
                      phone:'',
                      locstate: '',
                      address:'',
                      country: '',
                      primaryareas: [],
                      staffstrength: '',
                      photo: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                      <div className='row my-3 py-3'>
                        <div className='col-md-8'>


                        <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                          <label htmlFor="photo">Upload your company logo</label>
                          {uploadedPic && <img className='my-2' src={URL.createObjectURL(uploadedPic)} class="img-fluid mt-2" width={200} height={100} id="output_image"/>}
                        </div>
                        <div className='col-md-9'>
                            <input id="photo" name="photo" type="file" onChange={(event) => {
                                setFieldValue("photo", event.currentTarget.files[0]);
                                setUploadedPic(event.currentTarget.files[0]);
                            }} />
            
                             {touched.photo && errors.photo && <div className='py-1'>
                              <span className='text-danger'>{touched.photo && errors.photo}</span>
                        </div>}
                      </div>
                      </div>

          
                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="phone">Phone</label>
                          </div>
                          <div className='col-md-9'>
                          <Field
                            id="phone"
                            name="phone"
                            value={values.phone}
                            onBlur={handleBlur('phone')}
                            autoCapitalize="none"
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                        {touched.phone && errors.phone && <div className='py-1'>
                              <span className='text-danger'>{touched.phone && errors.phone}</span>
                        </div>}
                      </div>
                      </div>


                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="country">Country</label>
                          </div>
                          <div className='col-md-9'>
                            <Field
                              id="country"
                              name="country"
                              value={values.country}
                              onBlur={handleBlur('country')}
                              autoCapitalize="none"
                              style={{
                                padding: 10
                            }}
                            className="form-control"
                            />
                            
                            {touched.country && errors.country && <div className='py-1'>
                                <span className='text-danger'>{touched.country && errors.country}</span>
                          </div>}
                        </div>
                        </div>
          
                        <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="address">Address</label>
                          </div>
                          <div className='col-md-9'>
                          <Field
                            as='textarea'
                            id="address"
                            name="address"
                            value={values.address}
                            onBlur={handleBlur('address')}
                            autoCapitalize="none"
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                        {touched.address && errors.address && <div className='py-1'>
                              <span className='text-danger'>{touched.address && errors.address}</span>
                        </div>}
                      </div>
                      </div>

                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="locstate">State</label>
                          </div>
                          <div className='col-md-9'>
                          <Field
                            id="locstate"
                            name="locstate"
                            value={values.locstate}
                            onBlur={handleBlur('locstate')}
                            autoCapitalize="none"
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                        {touched.locstate && errors.locstate && <div className='py-1'>
                              <span className='text-danger'>{touched.locstate && errors.locstate}</span>
                        </div>}
                      </div>
                      </div>

                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="primaryareas">Job category</label>
                          </div>
                          <div className='col-md-9'>
                            <Field
                              as='select'
                              id="primaryareas"
                              name="primaryareas"
                              multiple={true}
                              value={values.primaryareas}
                              onBlur={handleBlur('primaryareas')}
                              autoCapitalize="none"
                              style={{
                                padding: 10
                            }}
                            
                            className="form-control"
                            >
                              <option value="">Select primary areas...</option>
                              {categories && categories.map((item,i) => {
                                return <option key={i} value={item.jcat}>{item.jcat}</option>
                              })}
                            </Field>
                            
                            {touched.primaryareas && errors.primaryareas && <div className='py-1'>
                                <span className='text-danger'>{touched.primaryareas && errors.primaryareas}</span>
                          </div>}
                        </div>
                        </div>


                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="staffstrength">Staff strength</label>
                          </div>
                          <div className='col-md-9'>
                            <Field
                              as='select'
                              id="staffstrength"
                              name="staffstrength"
                              value={values.staffstrength}
                              onBlur={handleBlur('staffstrength')}
                              autoCapitalize="none"
                              style={{
                                padding: 10
                            }}
                            className="form-control"
                            >
                              <option value="">Staff strength...</option>
                              <option value="1">1</option>
                              <option value="2-5">2-5</option>
                              <option value="6-15">6-15</option>
                              <option value="16 and above">16 and above</option>
                              
                            </Field>
                            
                            {touched.staffstrength && errors.staffstrength && <div className='py-1'>
                                <span className='text-danger'>{touched.staffstrength && errors.staffstrength}</span>
                          </div>}
                        </div>
                        </div>


                       <button
                          type='submit'
                          onClick={handleSubmit}
                          title="Submit"
                          disabled={!isValid || isSubmitting}
                            className='btn btn-primary'
                        >
                            Submit
                        </button>
                        </div>
                      </div>
                     
                        </form>                    )}
                  </Formik>}
      </div>
      <Footer />
    </div>
    )
}

export default SignupCompanyStep2;