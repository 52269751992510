import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Header, Footer } from './';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SiteContext } from "../context/index";

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .label('Username')
    .required()
    .min(4, 'Must have at least 4 characters'),
  password: Yup.string()
    .label('Password')
    .required()
    .min(5, 'Password must have at least 5 characters ')
});

const Login = () => {
  const [msg, setMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const { handleSignIn, apiUrl, handleCurrentPage } = useContext(SiteContext);

  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {

    if (location.state !== '') {
      setMsg(location.state);
      setTimeout(() => {
        setMsg(null);
      }, 3000)
    }
    handleCurrentPage('');
  }, [])


  const handleSubmit = (values, { setSubmitting }) => {

    let formData = new FormData();

    formData.append('uname', values.username);
    formData.append('upass', values.password);
    formData.append('action', 'auth');

    return fetch(`${apiUrl}/api/auth.php`, {
      method: 'post',
      body: formData
    })
      .then(processResponse)
      .then(async (res) => {
        console.log(res);
        let { status, data } = res;

        if (status === 200) {
          await handleSignIn(data);

          navigate('/');
          setSubmitting(false);
        } else if (status === 400) {
          setErrorMsg(data.msg);
          setTimeout(() => {
            setErrorMsg(null);
          }, 3000);
          setSubmitting(false);
        } else {
          alert(
            'Login failed',
            'Error connection to the server please check your details and try again',
            [{ text: 'Okay' }]
          );
        }
      })
      .catch((error) => {
        console.log('Api call error', error.message);
      });
  };

  const processResponse = (response) => {
    let status = response.status;
    let data = response.json();
    return Promise.all([status, data]).then((res) => ({
      status: res[0],
      data: res[1]
    }));
  };


  return (
    <div>
      <Header />
      <div className='container justify-content-center'>
        <div className='my-3'>
          <h2 className='mb-3'>Login</h2>

          {msg !== null ? <span className='px-2 border border-dark text-dark'>{msg}</span> : null}
          {errorMsg !== null ? <span className='px-2 border border-danger text-danger'>{errorMsg}</span> : null}

          <div className='mt-3 d-flex justify-content-center'>
            <Formik
              initialValues={{
                username: '',
                password: ''
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values, { setSubmitting });
              }}
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                isValid,
                isSubmitting,
                handleBlur,
                touched,
                setFieldValue
              }) => (
                <form onSubmit={handleSubmit} className='justify-content-center'>
                  <div className='form-group my-1'>
                    
                    <Field
                      id="username"
                      name="username"
                      value={values.username}
                      placeholder="Username"
                      onBlur={handleBlur('username')}
                      autoCapitalize="none"
                      style={{
                        padding: 10,
                        width: 300,
                      }}
                      className='border border-dark border-1 rounded'
                      />
                    {touched.username && errors.username && <div className='py-1'>
                      <span className='text-danger'>{touched.username && errors.username}</span>
                    </div>}
                  </div>

                  <div className='form-group my-2'>
                    
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      value={values.password}
                      placeholder="Password"
                      onBlur={handleBlur('password')}
                      style={{
                        padding: 10,
                        width: 300
                      }}
                      className='border border-dark border-1 rounded'
                      />

                    {touched.password && errors.password && <div className='py-1'>
                      <span className='text-danger'>{touched.password && errors.password}</span>
                    </div>}
                  </div>


                  <button
                    type='submit'
                    onClick={handleSubmit}
                    title="Create Account"
                    disabled={!isValid || isSubmitting}
                    className='btn btn-primary my-2'
                  >
                    Login
                  </button>

                </form>
              )}
            </Formik>
          </div>
          <div className='mt-2 text-center'>Forgot your password? <Link className='my-3 py-2 text-decoration-none h6' to='/forgot-password'>Reset it here</Link></div>


        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Login;