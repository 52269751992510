import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer } from './';
import { Pagination } from '../components';
import moment from 'moment';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import { DashboardMenu } from '../components';
import { SiteContext } from '../context';
import ReactModal from 'react-modal';

const validationSchema = Yup.object().shape({
    cat: Yup.string()
        .label('Category')
        .required()
});

const ArticleCategories = () => {

    let navigate = useNavigate();

    const { apiUrl } = useContext(SiteContext);

    const userData = JSON.parse(localStorage.getItem('user'));

    const [articleCats, setArticleCats] = useState([]);
    const [updateItem, setUpdateItem] = useState({});
    const [itemSelected, setItemSelected] = useState('');
    const [action, setAction] = useState('create');
    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        getArticleCategories();
    }, [])

    const getArticleCategories = () => {
        fetch(`${apiUrl}/api/articlecategories.php?action=fetch&role=${userData.role}`, {
            method: 'get'
        }).then((res) => {
            return res.json()
        }).then((res) => {

            if (res.status === 200) {
                if (res.rows.length > 0) {
                    setArticleCats(res.rows);
                }
            } else if (res.status === 400) {
                setMsg(res.msg)
                setTimeout(() => {
                    setMsg('')
                }, 3000)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleAdd = () => {
        setUpdateItem({});
        setAction('create');
    }


    const handleUpdate = (artcat) => {
        setUpdateItem(artcat);
        setAction('update');
    }

    const handleDelete = (catid) => {
        setItemSelected(catid);
        setShowModal(true);
    }


    const doDelete = (itemSelected) => {
        let formData = new FormData();

        formData.append('catid', itemSelected);
        formData.append('role', userData.role);
        formData.append('action', 'delete');

        fetch(`${apiUrl}/api/articlecategories.php`, {
            method: 'post',
            body: formData
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 200) {
                setShowModal(false);
                setMsg(res.msg)
                setTimeout(() => {
                    setMsg('')
                }, 3000)
                getArticleCategories();
            } else if (res.status === 400) {
                setErrorMsg(res.msg)
                setTimeout(() => {
                    setErrorMsg('')
                }, 3000)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleSubmit = (values, { setSubmitting, resetForm, setFieldValue }) => {

        let formData = new FormData();

        formData.append('catid', action === 'create' ? '' : updateItem.catid);
        formData.append('cat', values.cat);
        formData.append('role', userData.role);
        formData.append('action', action);

        return fetch(`${apiUrl}/api/articlecategories.php`, {
            method: 'post',
            body: formData
        })
            .then((res) => res.json())
            .then(async (res) => {

                if (res.status === 200) {
                    setSubmitting(false);
                    resetForm({});
                    setMsg(res.msg);
                    getArticleCategories();
                    setFieldValue("cat", '');
                    setTimeout(() => {
                        setMsg('');
                    }, 3000);
                } else if (res.status === 400) {
                    setErrorMsg(res.msg);
                    setTimeout(() => {
                        setErrorMsg('');
                    }, 5000);
                    setSubmitting(false);
                } else {
                    alert(
                        'Article category submission failed',
                        'Error connection to the server please check your details and try again',
                        [{ text: 'Okay' }]
                    );
                }
            })
            .catch((error) => {
                console.log('Api call error', error.message);
            });
    };



    return (
        <div>
            <ReactModal
                isOpen={showModal}
                style={{
                    overlay: {
                        flex: 1,
                        justifyContent: 'center',
                        alignContent: 'center',
                        position: 'fixed',
                        top: '10%',
                        bottom: '10%',
                        left: '25%',
                        right: '25%',
                        width: '50%',
                        height: 250,
                        backgroundColor: 'rgba(255, 255, 255, 0.75)'
                    },
                    content: {
                        position: 'absolute',
                        top: '20px',
                        left: '20px',
                        right: '20px',
                        bottom: '20px',
                        border: '1px solid #ccc',
                        background: '#fff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '20px'
                    }
                }}>
                <div>
                    <p className='h3 my-1 py-1'>Confirm deletion</p>
                    <p className='my-1 py-2'>Action cannot be reversed!</p>
                    <span className='btn btn-primary' onClick={() => doDelete(itemSelected)}>Confirm</span> <span className='btn btn-secondary' onClick={() => setShowModal(false)}>Cancel</span>
                </div>
            </ReactModal>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                        <div className='container my-2'>
                            <h3>Article Categories | <Link to='/articles'>Articles</Link></h3>
                            <div className='my-2 d-flex justify-content-between'>
                                <div className='align-items-center'>
                                    {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                                    {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                                </div>
                                <div>
                                    <button className='btn btn-primary' onClick={() => handleAdd()}>New Category</button>
                                </div>
                            </div>


                            {articleCats.length > 0 &&
                                <div className='container p-3'>
                                    <div className='row border p-1 bg-light'>
                                        <div className='col-1'>No</div>
                                        <div className='col-9'>Item</div>
                                        <div className='col-2'>Action</div>
                                    </div>
                                    {articleCats.map((articlecat, i) => {
                                        return (
                                            <div key={i} className='row border-bottom py-1'>
                                                <div className='col-1'>{++i}</div>
                                                <div className='col-9'>{articlecat.catname} </div>
                                                <div className='col-2'><a onClick={() => handleUpdate(articlecat)}><i className='bi bi-pencil text-dark h4'></i></a> <a onClick={() => handleDelete(articlecat.catid)}><i className='bi bi-trash text-danger h4'></i></a> </div>
                                            </div>
                                        )
                                    })}
                                </div>}

                            <Formik
                                initialValues={{
                                    cat: action === 'create' ? '' : updateItem.catname,
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    handleSubmit(values, { setSubmitting, resetForm });
                                }}
                            >
                                {({
                                    handleChange,
                                    values,
                                    handleSubmit,
                                    errors,
                                    isValid,
                                    isSubmitting,
                                    handleBlur,
                                    touched,
                                    setFieldValue
                                }) => (
                                    <div>
                                        <form onSubmit={handleSubmit} encype="multipart/form-data">
                                            <div className='row my-2'>
                                                <div className='col-md-8'>

                                                    <div className='form-group my-1'>
                                                        <div>
                                                            <label htmlFor="cat">Category</label>
                                                        </div>
                                                        <Field
                                                            id="cat"
                                                            name="cat"
                                                            value={values.cat || updateItem.catname}
                                                            onChangeText={handleChange('cat')}
                                                            placeholder="Category"
                                                            placeholderTextColor="#999"
                                                            onBlur={handleBlur('cat')}
                                                            autoCapitalize="none"
                                                            style={{
                                                                padding: 10
                                                            }}
                                                            className="form-control"
                                                        />

                                                        {touched.cat && errors.cat && <div className='py-1'>
                                                            <span className='text-danger'>{touched.cat && errors.cat}</span>
                                                        </div>}
                                                    </div>

                                                </div>
                                            </div>
                                            <button
                                                type='submit'
                                                buttonType="outline"
                                                onClick={handleSubmit}
                                                title="Submit"
                                                disabled={!isValid || isSubmitting}
                                                loading={isSubmitting}
                                                className='btn btn-primary'
                                            >
                                                {action === 'create' ? 'Submit' : 'Update'}
                                            </button>


                                            <button
                                                onClick={() => navigate(-1)}
                                                title="Cancel"
                                                className='btn btn-secondary mx-2'
                                            >
                                                Cancel
                                            </button>

                                        </form>
                                    </div>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ArticleCategories;