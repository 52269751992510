import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {Header, Footer} from './';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SiteContext } from "../context/index";

const validationSchema = Yup.object().shape({
    username: Yup.string()
      .label('Username or email address')
      .required()
      .min(4, 'Must have at least 4 characters')
  });
  
const ForgotPassword = () => {
    const [msg, setMsg] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const { handleSignIn, apiUrl  } = useContext(SiteContext);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
      
      if(location.state !== '') {
        setMsg(location.state);
        setTimeout(() => {
          setMsg(null);
        }, 3000)
      }
    },[])


    const handleSubmit = (values, { setSubmitting }) => {

        let formData = new FormData();

        formData.append('uname', values.username);
        formData.append('action', 'forgotpassword');

        return fetch(`${apiUrl}/api/users.php`, {
          method: 'post',
          body: formData
        })
          .then((res) => res.json())
          .then(async (res) => {
            console.log(res);
    
            if(res.status === 200) {
                setMsg(res.msg);
                setTimeout(() => {
                  setMsg(null);
                }, 5000);
                setSubmitting(false);
            } else if (res.status === 400) {
                 setErrorMsg(res.msg);
                setTimeout(() => {
                  setErrorMsg(null);
                }, 5000);
                setSubmitting(false);
            } else if (res.status === 450) {
                setErrorMsg(res.msg);
               setTimeout(() => {
                 setErrorMsg(null);
               }, 5000);
               setSubmitting(false);
           } else {
              alert(
                    'Login failed',
                    'Error connection to the server please check your details and try again',
                    [{ text: 'Okay' }]
                  );
            }
          })
          .catch((error) => {
            console.log('Api call error', error.message);
          });
      };
  
    

    return (
        <div>
            <Header />
                <div className='container d-flex justify-content-center'>
                <div className='my-3'>
                <h2 className='mb-1'>Forgot your password?</h2>
                <div className='my-2 text-center'>Provide your username or email address and we will send your new password to the registered email address</div>


                {msg !== null ? <span className='p-2 my-3 border border-dark text-dark'>{msg}</span> : null}
                {errorMsg !== null ? <span className='p-2 my-3 border border-danger text-danger'>{errorMsg}</span> : null}

                <div className='mt-3 d-flex justify-content-center'>
                <Formik
                        initialValues={{
                            username:'',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            handleSubmit(values, { setSubmitting });
                        }}
                        >
                        {({
                            handleChange,
                            values,
                            handleSubmit,
                            errors,
                            isValid,
                            isSubmitting,
                            handleBlur,
                            touched,
                            setFieldValue
                        }) => (
                            <form onSubmit={handleSubmit} className='justify-content-center'>
                            <div className='form-group my-2'>
                                <div className='my-1'>
                                    <label htmlFor="username">Username or email</label>
                                </div>
                                <Field
                                    id="username"
                                    name="username"
                                    value={values.username}
                                    onBlur={handleBlur('username')}
                                    autoCapitalize="none"
                                    style={{
                                        padding: 7,
                                        width: 300,                                        
                                    }}
                                    className='border border-dark border-1 rounded'
                                />
                                {touched.username && errors.username && <div className='py-1'>
                                <span className='text-danger'>{touched.username && errors.username}</span>
                                </div>}
                            </div>
                                
                                <button
                                type='submit'
                                onClick={handleSubmit}
                                title="Submit"
                                disabled={!isValid || isSubmitting}
                                className='btn btn-primary my-2'
                                >
                                    Submit
                                </button>
                            
                                </form>
                        )}
                        </Formik>
                        </div>

                        <div className='mt-2 text-center'>Already registered? <Link className='my-3 py-2 text-decoration-none h6' to='/login'>Login</Link></div>


            </div>
            </div>
          <Footer />
    </div>
    )
}

export default ForgotPassword;