import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer } from './';
import { Pagination, NavigationBar } from '../components';
import moment from 'moment';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { DashboardMenu } from '../components';
import { useDocumentTitle } from '../utils';
import { SiteContext } from '../context';
import ReactModal from 'react-modal';
import Seo from './Seo';

const PageDetail = () => {

  let { id } = useParams();

  const { apiUrl, theme,siteMaxWidth, handleCurrentPage, siteconfig } = useContext(SiteContext);

  const [sitepage, setSitepage] = useState({});
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  // const metaData = {
  //   "title": id.charAt(0).toUpperCase() + id.slice(1).replace(/-/gi, " ") + " | Just Web Services",
  //   "desc": id.charAt(0).toUpperCase() + id.slice(1).replace("-", " "),
  //   "imgurl": "",
  //   "author": "AfamBC",
  //   "keywords": "websites, andrid, iOS, mobile app"
  // }

  // useDocumentTitle(metaData);

  useEffect(() => {
    getPage();
    handleCurrentPage(id === 'about' ? 'about' : '');
  }, [id])

  const getPage = () => {
    setLoading(true);
    setErrorMsg('');
    fetch(`${apiUrl}/api/pages.php?action=fetchsingle&id=${id}`, {
      method: 'get'
    }).then((res) => {
      return res.json()
    }).then((res) => {
      if (res.status === 200) {
        setSitepage(res.item);
        setLoading(false);
      } else if (res.status === 400) {
        setErrorMsg(res.msg);
        setLoading(false);
      }
    }).catch((err) => {
      console.log(err)
    })
  }


  return (
    <div style={{ 'backgroundColor': theme.backgroundColor, 'color': theme.color}}>
    <Seo
        title={id.charAt(0).toUpperCase() + id.slice(1).replace(/-/gi, " ") + " | " + siteconfig.shopname}
        description={id.charAt(0).toUpperCase() + id.slice(1).replace(/-/gi, " ")}
        url={`${apiUrl}/page/${id}`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />
      <Header />
      <div>
        <div style={{'maxWidth': siteMaxWidth}} className='container'>
          <NavigationBar section='Page' page={sitepage.cpagename} goback={false} />

          <h3>{sitepage.cpagename}</h3>

          <div className='my-2 d-flex justify-content-between'>
            <div className='align-items-center'>
              {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
              {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
            </div>
          </div>


          {sitepage && <div className='row my-2 py-2'>

            <div className='col-md-8' dangerouslySetInnerHTML={{ __html: sitepage.cpagecontent }} />
            {sitepage.cpagephoto != undefined && <div className='col-md-4 col-12'><img className='img-fluid' src={`${apiUrl}/${sitepage.cpagephoto}`} height={150} width='100%' alt={sitepage.cpagename} /></div>}
          </div>}

          {loading && <div className='d-flex justify-content-center align-content-center my-5'>
            <div class="spinner-border text-secondary" role="status" />
          </div>}

        </div>
      </div>
      <Footer />
    </div>

  )
}

export default PageDetail;