import React, { useEffect, useContext } from 'react';
import { SiteContext } from '../context';
import { useNavigate, Link } from 'react-router-dom';

const CartBox = ({section}) => {

    const navigate = useNavigate();

    const { apiUrl, products, formatAmount, cart, manageCart } = useContext(SiteContext);

    const getOrderTotal = () => {
        const cartItems = cart.slice();
        const total = cartItems.reduce((a, b) => a + parseFloat(b.total), 0);
    
        if (total > 0) {
          return parseFloat(total).toFixed(2);
        } else {
          return 0.00;
        }
      }

    const getProductImg = id => {
        let filteredProduct = products.filter((item) => item.id === id);

        return filteredProduct[0].picture; 
    }

    const confirmDelete = (product,item) => {
        let text = "Confirm deletion of "+ product +" from cart!";
        if (window.confirm(text) == true) {
            manageCart('delete', item);
        }
    }

    return (
        <div>
                <div className='my-2 py-1'>
                <h4>Cart</h4>

                {cart.length > 0 ? <div>
                    {cart.map((item, i) => {

                        return (
                            <div key={i}>
                                <div className='row border border-light bg-light rounded py-2'>
                                    <div className='col-2'>
                                        <img src={`${apiUrl}/${getProductImg(item.id)}`} height={60} width={60} alt='logo' />
                                    </div>
                                    <div className='col-5'>
                                        <Link to={`/products/detail/${item.item.toLowerCase().split(' ').join('-')}`} className='small text-decoration-none text-dark'>{item.item}</Link> <br /><span className='text-muted small'> ({item.price !== 0 && formatAmount(item.price)})</span>
                                    </div>
                                    <div className='col-2'>
                                        <span className='small'>x{item.qty} <span className='cursor-pointer' onClick={() => confirmDelete(item.item, item.id)}><i className='bi bi-trash text-danger h6'></i></span></span>
                                    </div>
                                    <div className='col-3 text-end'>
                                        <span className='small'>{item.total !== 0 && formatAmount(item.total)}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }

                    <div className='my-2 py-2 border-top border-dark d-flex justify-content-between'>
                        <span className='h6'>Total</span>
                        <span className='h6 text-end'>{formatAmount(getOrderTotal())}</span>
                    </div>
                    <div className='py-2 d-flex justify-content-between'>
                        <button className='px-2 py-1 h6 text-white bg-danger rounded' onClick={() => manageCart('clear')}>Empty your cart</button>
                        {section !== 'checkout' && <button className='px-3 py-2 h6 text-white bg-dark rounded' onClick={() => navigate('/checkout')}>Checkout</button>}
                    </div>
                    

                </div>
                    : <div>
                        <p>Your cart is empty</p>
                    </div>}
                </div>
        </div>
    )
}

export default CartBox;