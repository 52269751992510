import React from 'react';

export const removeTags = (str) => {
    if (str === null || str === '') {
        return false;
    } else {
        str = str.toString().replace(/<p>|<li>|<ul>|<\/ul>/gi, '');

        return str.replace(/[\r\n]|<\/p>|<\/li>/gm, ' ');
    }
}

export const getHeading = (text, words) => {
    let headingText = text.split(' ', words);
    let headingTextR = headingText.join(' ');
    let theText;

    if (headingText.length > words - 1) {
        theText = headingTextR + '...'.toString()
    } else {
        theText = headingTextR.toString()
    }
    return theText;
}

export const formatViews = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}