import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer } from './';
import { Pagination, NavigationBar } from '../components';
import { useNavigate, Link } from 'react-router-dom';
import { DashboardMenu } from '../components';
import { SiteContext } from '../context';
import ReactModal from 'react-modal';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    name: Yup.string()
      .label('Name')
      .required()
      .min(4, 'Must have at least 4 characters'),
    address: Yup.string()
      .label('Address')
      .required()
      .min(5, 'Must have at least 5 characters'),
    locstate: Yup.string()
        .label('State')
        .required()
        .min(3, 'Must have at least 3 characters'),
    email: Yup.string()
      .label('Email')
      .required()
      .min(5, 'Must have at least 11 characters'),
    phone: Yup.string()
      .label('Phone')
      .required()
      });


const Companies = () => {

  let navigate = useNavigate();

  const { apiUrl, isMobile } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem('user'));

  const [companies, setCompanies] = useState([]);
  const [mode, setMode] = useState('');
  const [uploadedPic, setUploadedPic] =  useState(null);
  const [total, setTotal] = useState(null);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [updateItem, setUpdateItem] = useState({});
  const [itemSelected, setItemSelected] = useState('');
  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModalCompany, setShowModalCompany] = useState(false);

  useEffect(() => {
    getCompanies();
  }, [page, limit])

  const getCompanies = () => {
    fetch(`${apiUrl}/api/companies.php?action=fetch&role=${userData.role}&page=${page}&limit=${limit}`, {
      method: 'get'
    }).then((res) => {
      return res.json()
    }).then((res) => {

      if (res.status === 200) {
        if (res.rows.length > 0) {
          setCompanies(res.rows);
          setTotal(res.rows.length);
        }
      } else if (res.status === 400) {
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        }, 5000)
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleSubmit = (values, { setSubmitting, resetForm, setShowModalCompany }) => {

    let formData = new FormData();
  
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('phone', values.phone);
    formData.append('address', values.address);
    formData.append('locstate', values.locstate);
    formData.append('loccountry', values.loccountry);
    formData.append('picture', values.picture);
    formData.append('compid', values.id);
    formData.append('action', mode);
  
    const url = `${apiUrl}/api/companies.php`;
    const sendType = 'post';
  
      return fetch(url, {
        method: sendType,
        body: formData
      })
        .then((res) => res.json())
        .then((res) => {

          if(res.status === 200) {
            setMsg(res.msg);
            resetForm({});
            setSubmitting(false);      
            getCompanies();
            setTimeout(() => {
                setMsg('');
              }, 3000);
            setTimeout(() => {
                setShowModalCompany(false);
            }, 4000);
          } else if(res.status === 400) {
            setSubmitting(false);
            setErrorMsg(res.msg);
            setTimeout(() => {
              setErrorMsg('');
            }, 2000);
          } else {
            alert(
                  'Failure',
                  'Error connection to the server please check your details and try again',
                  [{ text: 'Okay' }]
                );
          }
        })
        .catch((error) => {
          console.log('Api call error', error.message);
        });
    };
  

  const doPrevious = async () => {

    if (parseInt(page) > 1) {
      await setPage(() => parseInt(page - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCangoBack(false);
      setCangoForward(true);
    }
  }

  const doNext = async () => {

    if (parseInt(page * limit) < total) {
      await setPage(() => parseInt(page + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCangoForward(false);
      setCangoBack(true);
    }
  }

  const handleChange = (event) => {
    let newLimit = event.target.value;
    setLimit(newLimit);
  }

  const handleDelete = (id) => {
    setItemSelected(id);
    setShowModal(true);
  }


  const doDelete = (itemSelected) => {
    setShowModal(false);

    let formData = new FormData();

    formData.append('compid', itemSelected);
    formData.append('role', userData.role);
    formData.append('action', 'delete');

    fetch(`${apiUrl}/api/companies.php`, {
      method: 'post',
      body: formData
    }).then((res) => {
      return res.json()
    }).then((res) => {
      if (res.status === 200) {
        setMsg(res.msg);
        getCompanies();
        setTimeout(() => {
          setMsg('');
        }, 3000);
          } else if (res.status === 400) {
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        }, 3000);
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleAction = (type,obj) => {

      if(type === 'add') {
        setMode('create');
        setUpdateItem({});
        setUploadedPic(null);
        setShowModalCompany(true);
      } else {
          setMode('update');
          setUpdateItem(obj);
          setShowModalCompany(true);
      }
  }

  return (
    <div>
      <ReactModal
        isOpen={showModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            position: 'fixed',
            top: '10%',
            bottom: '10%',
            left: isMobile ? '10%' : '25%',
            right: isMobile ? '10%' : '25%',
            width: isMobile ? '80%' : '50%',
            height: 250,
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '20px',
            left: '20px',
            right: '20px',
            bottom: '20px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px'
          }
        }}>
        <div>
          <p className='h3 my-1 py-1'>Confirm deletion</p>
          <p className='my-1 py-2'>Action cannot be reversed!</p>
          <span className='btn btn-primary' onClick={() => doDelete(itemSelected)}>Confirm</span> <span className='btn btn-secondary' onClick={() => setShowModal(false)}>Cancel</span>
        </div>
      </ReactModal>

      <ReactModal
              isOpen={showModalCompany}
              style={{
                overlay: {
                  flex: 1,
                  justifyContent: 'center',
                  alignContent: 'center',
                  position: 'fixed',
                  top: '20%',
                  bottom: '10%',
                  left: isMobile ? '10%' : '25%',
                  right: isMobile ? '10%' : '25%',
                  width: isMobile ? '80%' : '50%',
                  height: '70%',
                  backgroundColor: 'rgba(255, 255, 255, 0.75)'
                },
                content: {
                  position: 'absolute',
                  top: '20px',
                  left: '20px',
                  right: '20px',
                  bottom: '20px',
                  border: '1px solid #ccc',
                  background: '#fff',
                  overflow: 'auto',
                  WebkitOverflowScrolling: 'touch',
                  borderRadius: '4px',
                  outline: 'none',
                  padding: '20px'
                }
              }}>
              <div>
      
      <Formik
                    initialValues={{
                      id: updateItem.id ? updateItem.id : '',
                      name: updateItem.companyname ? updateItem.companyname : '',
                      email: updateItem.email ? updateItem.email : '',
                      phone: updateItem.phone ? updateItem.phone : '',
                      address: updateItem.address ? updateItem.address : '',
                      locstate: updateItem.location_state ? updateItem.location_state : '',
                      loccountry: updateItem.location_country ? updateItem.location_country : '',
                      picture: updateItem.company_logo ? updateItem.company_logo : '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                      setTimeout(() => {
                        setShowModalCompany(false);
                      },1000);
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit}  encype="multipart/form-data">
                        <div className='row'>
                          <div className='col-md-6'>


                          <div className='form-group my-2'>
                          <div>
                            <label htmlFor="name">Name</label>
                          </div>
                          <Field
                            id="name"
                            name="name"
                            value={values.name}
                            placeholder="Name"
                            onBlur={handleBlur('name')}
                            autoCapitalize="none"
                            style={{
                              padding: 4
                          }}
                          className="form-control"
                          />
                          {touched.name && errors.name && <div className='py-1'>
                                <span className='text-danger'>{touched.name && errors.name}</span>
                          </div>}
                        </div>


                          <div className='form-group my-1'>
                            <div>
                              <label htmlFor="email">Email</label>
                            </div>
                            <Field
                              id="email"
                              name="email"
                              value={values.email}
                              placeholder="Email"
                              onBlur={handleBlur('email')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            row='5'
                            />
                          {touched.email && errors.email && <div className='py-1'>
                                <span className='text-danger'>{touched.email && errors.email}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                            <div>
                              <label htmlFor="phone">Phone</label>
                            </div>
                            <Field
                              id="phone"
                              name="phone"
                              value={values.phone}
                              placeholder="Phone"
                              onBlur={handleBlur('phone')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            row='5'
                            />
                          {touched.phone && errors.phone && <div className='py-1'>
                                <span className='text-danger'>{touched.phone && errors.phone}</span>
                          </div>}
                        </div>

                        <div className='form-group my-2'>
                          <div>
                            <label htmlFor="picture"> Logo</label>
                          </div>
                          <input id="picture" name="picture" type="file" onChange={(event) => {
                                 setFieldValue("picture", event.currentTarget.files[0]);
                                 setUploadedPic(event.currentTarget.files[0]);
                                }} />
                        </div>

                        {uploadedPic && <img className='my-2' src={URL.createObjectURL(uploadedPic)} class="img-fluid mt-2" width={80} height={80} id="output_image"/>}
                        {updateItem.hasOwnProperty('company_logo') && <img className='my-2' src={`${apiUrl}/${updateItem.company_logo}`} class="img-fluid mt-2" width={80} height={80} id="logo"/>}




                          </div>
                          <div className='col-md-6'>
                            
                          <div className='form-group my-1'>
                          <div>
                            <label htmlFor="address">Address</label>
                          </div>
                            <Field
                              as="textarea"
                              id="address"
                              name="address"
                              value={values.address}
                              placeholder="Address"
                              onBlur={handleBlur('address')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.address && errors.address && <div className='py-1'>
                                <span className='text-danger'>{touched.address && errors.address}</span>
                          </div>}
                        </div>


                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="locstate">State</label>
                          </div>
                          <Field
                            id="locstate"
                            name="locstate"
                            value={values.locstate}
                            placeholder="State"
                            onBlur={handleBlur('locstate')}
                            autoCapitalize="none"
                            style={{
                              padding: 4
                          }}
                          className="form-control"
                          />
                            {touched.locstate && errors.locstate && <div className='py-1'>
                                <span className='text-danger'>{touched.locstate && errors.locstate}</span>
                            </div>}
                          </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="loccountry">Country</label>
                          </div>
                            <Field
                              id="loccountry"
                              name="loccountry"
                              value={values.loccountry}
                              placeholder="Country"
                              onBlur={handleBlur('loccountry')}
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          
                          {touched.loccountry && errors.loccountry && <div className='py-1'>
                                <span className='text-danger'>{touched.loccountry && errors.loccountry}</span>
                          </div>}
                        </div>


                          </div>
                        </div>
                         
                         
                          <button
                            type='submit'
                            onClick={handleSubmit}
                            title={mode}
                            disabled={!isValid || isSubmitting}
                              className='btn btn-primary my-2'
                          >
                              {mode.charAt(0).toUpperCase()+''+mode.slice(1)}
                          </button>

                          
                    <button
                      onClick={() => setShowModalCompany(false)}
                      title="Cancel"
                      className='btn btn-secondary mx-2'
                    >
                      Cancel
                    </button>
                        
                          </form>
                    )}
                  </Formik>
                  </div>
      </ReactModal>
      <Header />
      <div>
        <div className='row'>
          <div className='col-md-2 bg-light'>
            <DashboardMenu />
          </div>
          <div className='col-md-10'>
            <div className='container my-2'>
            <NavigationBar section={'Products'} goback={true} />

              <h3>Companies | <Link to='/adminproducts'>Products</Link></h3>
              <div className='my-2 d-flex justify-content-between'>
                <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                  {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                </div>
                <div>
                  <button className='btn btn-primary' onClick={() => handleAction('add',{})}>New</button>
                </div>
              </div>

              {companies.length > 0 && <Pagination limit={limit} page={page} total={total} cangoBack={cangoBack} cangoForward={cangoForward} handleChange={handleChange} doNext={doNext} doPrevious={doPrevious} />}

              {companies.length > 0 &&
                <div className='container p-3'>
                  <div className='row border p-1 bg-light'>
                    <div className='col-1'>No</div>
                    <div className='col-6'>Name</div>
                    <div className='col-3'>Contact</div>
                    <div className='col-2'>Action</div>
                  </div>
                  {companies.map((item, i) => {
                    return (
                      <div key={i} className='row border-bottom py-1'>
                        <div className='col-1'>{++i}</div>
                        <div className='col-6'>{item.companyname}</div>
                        <div className='col-3'>{item.phone}<br />{item.email}</div>
                        <div className='col-2'><a onClick={() => handleAction('update', item)}><i className='bi bi-pencil text-dark h4'></i></a> <a onClick={() => handleDelete(item.id)}><i className='bi bi-trash text-danger h4'></i></a> </div>
                      </div>
                    )
                  })}
                </div>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Companies;