import React, {useState, useEffect, useContext} from 'react';
import { NavigationBar } from '../components';
import { useParams, Link } from 'react-router-dom';
import { SiteContext } from '../context';

const JobCategoryDetail = () => {

  let {slug} = useParams();

const { apiUrl, categories } = useContext(SiteContext);

const [category, setCategory] = useState({});

useEffect(() => {
    categoryUpdate();
    window.scrollTo(0,0);
},[slug])

const categoryUpdate = () => {
    let cat = categories.filter((item) => item.jcatslug === slug);
    setCategory(cat[0]);
}

    return (
        <div>
            
              <div className='p-2'>
                <div className='row'>
                    <div className='col-md-8'>
                    {category.hasOwnProperty('jcatid') ? <div className='my-1 py-2 rounded'>
                <div className='mt-3 p-2 bg-light'>
                    <Link to='/'>Home</Link> / <Link to='/categories'>Categories</Link> / <span style={{'text-transform':'capitalize'}}>{category.jcat}</span> 
                </div>
                    <h3 className='py-2'>{category.jcat}</h3>

                <img className='p-2' style={{'objectFit':'cover'}} src={`${apiUrl}/${category.jcatphoto}`} height={250} width='100%' alt={category.jcat} /><br />
                
                <div className='py-2' dangerouslySetInnerHTML={{__html: category.jcatdesc }} />

                <div className='mt-3 bg-warning text-white p-2 rounded'>
                            Categories
                        </div>
                        <div className='row p-3'>
                    {categories.map((item,i) => {
                        return (
                            <div key={i} className='col-md-2 col-6 p-2 my-1'>
                                <Link className='text-dark text-decoration-none' to={`/categories/category/${item.jcatslug}`}>
                                    <div className='card'>
                                        <div className='card-img'>
                                            <img style={{'objectFit':'cover'}} src={`${apiUrl}/${item.jcatphoto}`} width='100%' height={100} alt={item.jcat} />                                    
                                        </div>
                                        <div className='h6 card-img-overlay opacity-50 mx-0 px-0'>
                                            <p className='bg-dark text-white text-center py-1 w-100 small'>{item.jcat}</p>    
                                        </div>                                        
                                    </div>
                                </Link>
                            </div>
                        )
                    })}
                    </div>                  

                </div>: <div className='my-2'>Category {slug} not found</div>}
                    </div>
                    <div className='col-md-4 my-2'>
                        <div className='my-3 p-3 rounded bg-dark text-white'>
                            <p className='h4'>Get answers to your questions.</p>
                            <Link className='my-2 btn btn-primary py-2' to='/faqs'>Frequently Asked Questions</Link>    
                        </div>

                        <div className='my-3 p-3 rounded bg-info text-dark'>
                            <p className='h3 border-bottom py-2'>Worker</p>
                            <p className='h5'>Are you medically fit? Do you have experience? Ready to relocate to Canada for a better pay?</p>
                            <Link className='my-2 btn btn-light py-2' to='/signup'>Join AfriJobsConnect today</Link>    
                        </div>

                        <div className='my-3 p-3 rounded bg-danger text-light'>
                            <p className='h3 border-bottom py-2'>Employer of labor</p>
                            <p className='h5'>Need to connect with qualified and ready to engage workers from Africa?</p>
                            <Link className='my-2 btn btn-dark py-2' to='/signup/company'>Register as a company</Link>    
                        </div>


                    </div>
                </div>

            </div>

            </div>
    )
}

export default JobCategoryDetail;